import Paper from '@mui/material/Paper';
import React from 'react';


export const FieldPaper = ( { children, sx = {} } ) => {

    const paperFieldSx = { backgroundColor: "#041425", filter: "brightness( 70%)", borderRadius: 6, width: "90%", margin: 1, display: "flex", flexDirection: "row", padding: 2, color: "white", alignContent: "center", justifyContent: "flex-start", wrap: "wrap", ...sx };
    const paperFieldEl = 23;

    return <Paper elevation={ paperFieldEl } sx={ paperFieldSx }>{ children }</Paper>;

};
