import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserProfile } from "../firebase";
import { AuthContext } from "../router/Root";
import type { userObjectLiteral } from "../firebase";
import { Alert } from "../components/Alert";
import Snackbar from "@mui/material/Snackbar";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { getLoaders } from "../components/CustomLoader";
import { FieldPaper } from "../components/FieldPaper";
import { InputLabel, Skeleton } from "@mui/material";


function User () {
    const { uid } = useParams();
    const { user, loaded } = useContext( AuthContext );
    const [ data, setData ] = useState<userObjectLiteral | null>( null );
    const [ loading, setLoading ] = useState( true );
    const [ showError, setError ] = useState( false );
    const theme = useTheme();
    const isLargeEnough = useMediaQuery( theme.breakpoints.up( 'sm' ) );
    const containerClass = isLargeEnough ? 'profile-container flex-row' : 'profile-container flex-column';

    useEffect( () => {

        if ( loading && loaded )
        {
            const fetchData = async () => {
                try
                {
                    const userData = ( await UserProfile.getUserFromServer( uid ) ).profile;
                    setData( userData );
                }
                catch ( err )
                {
                    throw err;
                }
            };
            if ( !data && !showError )
            {

                fetchData().then( () => {
                    setLoading( false );
                } ).catch( ( err ) => {

                    setError( true );
                    //todo disconnected error ?

                    //! show error / doesn't exist (ask for reload ?)

                } );
            }



        }
    }, [ user, loaded, loading, uid, data, showError ] );

    return <>
        <div className="App">
            <div className="App-background">
                { loading ?
                    <Skeleton sx={ { bgcolor: '#041425' } } variant="text">
                        <div style={ { margin: "2vh", minWidth: '20vw', color: "#7a6800" } }>
                            <div>Welcome to { data?.displayName }'s profile !</div></div></Skeleton>
                    :
                    <div style={ { margin: "2vh", minWidth: '20vw', color: "#7a6800" } }>
                        <div>Welcome to { data?.displayName }'s profile !</div>
                    </div>
                }
                <div className={ containerClass }>

                    <LoadingPapers loading={ loading } data={ data } />
                    <LoadingPapersCharactersAndEvents loading={ loading } data={ data } />

                </div>
            </div>
        </div>
        <ErrorEl showError={ showError } />



    </>;
}
export default User;

const LoadingPapers = ( { loading, data } ) => {
    const theme = useTheme();
    const { Rounded } = getLoaders( loading );

    const isLargeEnough = useMediaQuery( theme.breakpoints.up( 'sm' ) );
    const subContainerClass = isLargeEnough ? 'subcontainer profile-subcontainer-isRow' : 'subcontainer profile-subcontainer-isColumn';
    const paperSx = { backgroundColor: "#282c34", filter: "brightness( 130%)", borderRadius: 6, };

    const matchingWidth = isLargeEnough ? "39vw" : "80vw";
    const style = { width: matchingWidth, padding: 3, marginBottom: 15, color: "#7a6800" };
    const labelProps = { color: "cyan", fontSize: "calc(7px + 0.7vmin)", textAlign: "start", marginBottom: 1 };
    //content: '\200B'
    const zeroWidthString = "\u200B";
    return (
        <Paper className={ subContainerClass } elevation={ 20 } sx={ paperSx } id="user-left">

            <Rounded>
                <FieldPaper sx={ { flexDirection: "column" } }>
                    <InputLabel variant={ "filled" } sx={ labelProps }>Their discord:</InputLabel>
                    <div style={ style } >{ data?.discord || zeroWidthString }</div></FieldPaper>
            </Rounded>
            <Rounded>
                <FieldPaper sx={ { flexDirection: "column" } }>
                    <InputLabel variant={ "filled" } sx={ labelProps }>Their favorite boss:</InputLabel>
                    <div style={ style }>{ data?.favourite_boss || zeroWidthString }</div></FieldPaper>
            </Rounded>
            <Rounded>
                <FieldPaper sx={ { flexDirection: "column" } }>
                    <InputLabel variant={ "filled" } sx={ labelProps }>Their main character:</InputLabel>
                    <div style={ style }>{ data?.characters?.[ 0 ] || zeroWidthString }</div>
                </FieldPaper>
            </Rounded>
            <Rounded>
                <FieldPaper sx={ { flexDirection: "column", minHeight: "20vh" } }>
                    <InputLabel variant={ "filled" } sx={ labelProps }>Their bio:</InputLabel>
                    <div style={ style }>{ data?.about || zeroWidthString }</div>
                </FieldPaper>
            </Rounded>


        </Paper>



    );
};
const LoadingPapersCharactersAndEvents = ( { loading, data } ) => {
    const theme = useTheme();
    const { Rounded } = getLoaders( loading );

    const isLargeEnough = useMediaQuery( theme.breakpoints.up( 'sm' ) );
    const subContainerClass = isLargeEnough ? 'subcontainer profile-subcontainer-isRow' : 'subcontainer profile-subcontainer-isColumn';
    const paperSx = { backgroundColor: "#282c34", filter: "brightness( 130%)", borderRadius: 6, };

    const matchingWidth = isLargeEnough ? "39vw" : "80vw";
    const style = { width: matchingWidth, padding: 3, marginBottom: 15, color: "#7a6800" };
    const labelProps = { color: "cyan", fontSize: "calc(7px + 0.7vmin)", textAlign: "start", marginBottom: 1 };
    //content: '\200B'
    const zeroWidthString = "\u200B";
    const characters = data?.characters && data?.characters[ 0 ] ? data?.characters : new Array( 4 ).fill( zeroWidthString );
    return (
        <Paper className={ subContainerClass } elevation={ 20 } sx={ paperSx } id="user-right">
            { characters.map( ( characterName: string, index: number ) => {

                return ( <Rounded key={ characterName + index }>
                    <FieldPaper sx={ { flexDirection: "column" } }>
                        <InputLabel variant={ "filled" } sx={ labelProps }>{ index ? "Character " + ( index + 1 ) + " name:" : "Main character's name:" }</InputLabel>
                        <div style={ style } >{ characterName || zeroWidthString } </div></FieldPaper>
                </Rounded> );


            }

            ) }
        </Paper>



    );
};



//<Loader loading={ loading } > Welcome to { data?.displayName } profile ! </Loader>

const ErrorEl = ( props ) => {
    const navigate = useNavigate();
    const [ openError, setOpenError ] = useState( false );
    const handleCloseError = () => {
        setOpenError( false );
    };
    const { showError } = props;
    const [ seconds, setSeconds ] = useState( 5 );

    useEffect( () => {
        if ( showError )
        {
            setOpenError( true );
        }
    }, [ showError ] );

    useEffect( () => {

        if ( showError && seconds > 0 )
        {
            const interval = setInterval( () => {
                setSeconds( seconds - 1 );
            }, 1000 );
            return () => clearInterval( interval );
        }
        else if ( showError )
        {
            navigate( "/" );
        }
    }, [ showError, seconds, navigate ] );

    return (
        <Snackbar
            open={ openError }
            autoHideDuration={ 6000 }
            onClose={ handleCloseError }

        >

            <Alert onClose={ handleCloseError } severity="error" sx={ { width: '100%' } } >An error occured. This user might not exist. you will be redirected in { seconds } seconds.</Alert>
        </Snackbar>
    );

};