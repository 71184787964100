import React from "react";
import { Button, Paper, Snackbar, TextField } from "@mui/material";
import { textStyle } from "../constants";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../router/Root";
import { Alert } from "../components/Alert";

export default function Login () {
    const [ email, setEmail ] = React.useState( "" );
    const [ password, setPassword ] = React.useState( "" );
    const [ passwordIsTooShort, setPasswordIsTooShort ] = React.useState( false );
    const [ noEmail, setNoEmail ] = React.useState( false );
    const { user } = React.useContext( AuthContext );
    const location = useLocation();
    const navigate = useNavigate();
    const [ error, setError ] = React.useState( false );
    const [ message, setMessage ] = React.useState( "" );

    const redirect = React.useCallback(
        ( link = "/" ) => navigate( link, { replace: true } ),
        [ navigate ]
    );
    React.useEffect( () => {
        if ( user )
        {
            // If we aren't logging out, we should redirect (the auth will take 1 more render to update user)
            if ( !( location?.state?.logout ) )
            {
                // redirect();
                redirect( "/profile" );
            }
        }


    }, [ user, redirect, location, location?.state, location?.state?.logout ] );

    const handleCloseError = () => {
        setError( false );
        setMessage( "" );
    };
    const login = async () => {
        if ( !email )
        {
            setNoEmail( true );
        }
        if ( !password || ( password.length < 8 ) )
        {
            setPasswordIsTooShort( true );
        }
        if ( !email || !password || ( password.length < 8 ) )
        {
            return;
        }
        try
        {
            //? Unneeded since we handle auth changes in the Auth listener
            // const userCredential =
            await signInWithEmailAndPassword( auth, email, password );

            // Signed in 
            // const uid = userCredential.user.uid;
            // const profile = await UserProfile.getUserFromServer( uid );
            // propagateProfile( profile );
            // console.log( profile );

            redirect( "/profile" );

            //todo redirect to mainpage
            // ...
        }
        catch ( error )
        {
            const errorMessage = error.message;
            switch ( errorMessage )
            {
                //todo error types
                case "auth/wrong-password":
                    setError( true );
                    setMessage( "Wrong password or email adress. Try again." );
                    break;
                case "auth/user-not-found":
                    setError( true );
                    setMessage( "Wrong password or email adress. Try again." );
                    break;
                case 'auth/too-many-requests':
                    setError( true );
                    setMessage( "An error occured with our data retrieval. Please hang tight and retry in a few minutes." );
                    break;
                default:
                    setError( true );
                    setMessage( "An unknown error occured. Try reloading the website or contacting the developer." );
                    break;
            }
        }
    };
    return (

        <div className="App">
            <header className="App-header">
                <Paper sx={ { backgroundColor: "#1976d2", padding: 2, marginBottom: "20vh", borderRadius: 4 } } elevation={ 3 }>
                    Log into the app
                </Paper>

                <TextField error={ noEmail } type={ "email" } sx={ textStyle } onChange={ ( e ) => setEmail( e.target.value ) } required id="filled-email" label={ noEmail ? "An email is required" : "E-mail" } variant="filled" />
                <TextField error={ passwordIsTooShort } type={ "password" } sx={ textStyle } onChange={ ( e ) => setPassword( e.target.value ) } required id="filled-pw" label={ passwordIsTooShort ? "password must be at least 8 characters" : "Password" } variant="filled" />
                <Button sx={ { marginTop: 5, backgroundColor: "darkgoldenrod", color: "black" } } onClick={ () => navigate( "/create" ) } variant="contained">Create account</Button>

                <Button sx={ { marginTop: 5 } } onClick={ () => login() } disabled={ ( !email || !password || password?.length < 8 ) } variant="contained">login</Button>
            </header>
            <Snackbar
                open={ error }
                autoHideDuration={ 6000 }
                onClose={ handleCloseError }

            >

                <Alert onClose={ handleCloseError } severity="error" sx={ { width: '100%' } } >{ message }</Alert>
            </Snackbar>
        </div>
    );
}