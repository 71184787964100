import React, { useMemo } from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
//TODO remove style property
export const CustomLoader = ( { loading, children, LoaderEl }: { loading: boolean; children: React.ReactNode; LoaderEl: React.ReactElement; } ) => {



    if ( loading )
    {
        return ( LoaderEl );
    }
    else
        return <>{ children }</>;

};
interface LoaderSkeletonProps { loading: boolean, children: React.ReactNode, variant: "text" | "rectangular" | "rounded" | "circular", style: { width: string, height: string; }; }
interface CurriedLoaderProps {
    loading: boolean;
    children: React.ReactNode;
    style: { width: string, height: string; };
}
interface LoaderProps { children: React.ReactNode, style?: { width: string, height: string; }; }
export const LoaderSkeleton = ( { loading, children, variant, style }: LoaderSkeletonProps ) => {
    return useMemo( () => <CustomLoader loading={ loading } LoaderEl={ <Box sx={ { margin: 1 } }><Skeleton sx={ { bgcolor: '#041425' } } variant={ variant } children={ children } /></Box> } ><Box sx={ { margin: 1 } }>{ children }</Box></CustomLoader>, [ loading, children, variant ] );
};


export const LoaderText = ( props: CurriedLoaderProps ) => <LoaderSkeleton { ...props } variant="text" />;
export const LoaderRectangular = ( props: CurriedLoaderProps ) => <LoaderSkeleton { ...props } variant="rectangular" />;
export const LoaderRounded = ( props: CurriedLoaderProps ) => <LoaderSkeleton { ...props } variant="rounded" />;
export const LoaderCircular = ( props: CurriedLoaderProps ) => <LoaderSkeleton { ...props } variant="circular" />;


export const getLoaders = ( loading: boolean ) => {
    const Text = ( props: LoaderProps ) => <LoaderText style={ { width: "100%", height: "100%" } } { ...props } loading={ loading } />;
    const Rectangular = ( props: LoaderProps ) => <LoaderRectangular style={ { width: "100%", height: "100%" } } loading={ loading } { ...props } />;
    const Rounded = ( props: LoaderProps ) => <LoaderRounded style={ { width: "100%", height: "80%" } } loading={ loading }{ ...props } />;
    const Circular = ( props: LoaderProps ) => <LoaderCircular style={ { width: "100%", height: "100%" } } loading={ loading } { ...props } />;
    return {
        Text,
        Rectangular,
        Rounded,
        Circular
    };
};
