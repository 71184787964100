import React from "react";
import { StrictMode } from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import CreateAccount from "../auth/CreateAccount";
import Login from "../auth/Login";
import Main from "../Main";
import Root from "./Root";
import Profile from "../auth/Profile";
import User from "../users/User";
import EventView from "../events/Events";
import AdminPanel from "../auth/AdminPanel";
export const Router = () => {

    const router = createBrowserRouter( [
        {
            path: "/",
            element: <Root />,
            errorElement: <Navigate to={ "/" }></Navigate>,
            children: [
                {
                    path: "/",

                    element: <Main />
                },
                {
                    path: "/create",
                    element: <CreateAccount />
                },
                {
                    path: "/login",
                    element: <Login />
                },
                {
                    path: "/profile",
                    element: <Profile />
                },
                {
                    path: "/user/:uid",
                    element: <User />
                },
                {
                    path: "/events/:type",
                    element: <EventView />
                },
                {
                    path: "/admin",
                    element: <AdminPanel />
                }
                // todo : Add singular view for an event
                // {
                //     path: "/event/:uid",
                //     element: <User />
                // }
            ]
        },
    ]
    );

    return (
        <>
            <StrictMode>
                <RouterProvider router={ router } />
            </StrictMode>
        </>
    );
};

export default Router;
