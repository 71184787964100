import React, { useContext } from 'react';
import { Button, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import logo from './elden.png';
import './App.css';
// import { querySnapshot, add, hide, finished, killed } from "./firebase";
import Box from '@mui/system/Box';
import { AuthContext } from './router/Root';
import { useNavigate } from 'react-router-dom';
// import { getAuth } from 'firebase/auth';


//TODO add "done" for whoever has successfully killed the boss !
function Main () {
    const theme = useTheme();

    const q = useMediaQuery( theme.breakpoints.up( "md" ) );
    const { user } = useContext( AuthContext );
    const navigate = useNavigate();
    const emoji = String.fromCodePoint( Number.parseInt( '0x1F44B' ) );
    return (
        <div className="App">
            <header className="App-background">
                <Box sx={ { margin: 1 } }>

                    { !logo ? <Skeleton sx={ { bgcolor: '#1976d2' } } variant={ 'circular' }>

                        <img src={ logo } className="App-logo" alt="logo" />
                    </Skeleton> : <img src={ logo } className="App-logo" alt="logo" />
                    }
                </Box>

                <h1 style={ { marginTop: "1em", marginBottom: "5vh", color: '#7a6800' } }>
                    Fight bosses with us during our community-driven events !
                </h1>
                <div style={ { marginBottom: "1em", display: "flex", alignContent: "center", justifyContent: "center", flexDirection: q ? "row" : 'column', flexWrap: "wrap", width: q ? "550px" : "" } }>
                    <Button variant="outlined" type='a' sx={ { flex: 1, margin: 1, color: "#9147ff" } } color='warning' href="https://www.twitch.tv/dekharen" target={ "_blank" }>The stream</Button>
                    <Button variant="outlined" type='a' sx={ { flex: 1, margin: 1 } } color='warning' href="https://discord.gg/eldenring" target={ "_blank" }>The ER server</Button>
                    <Button variant="outlined" type='a' sx={ { flex: 1, margin: 1, color: 'red' } } color='warning' href="https://www.paypal.com/donate/?hosted_button_id=9KLBV4WS8YY9C" target={ "_blank" }>Donate</Button>

                </div>
                <div className="form-style">

                    { user ?
                        <>
                            <Typography sx={ { fontSize: "inherit", color: '#7a6800' } } >Nice to see you { `${ user.displayName }${ emoji }` }  </Typography>
                            <Button onClick={ () => { navigate( "/events/participating" ); } } style={ { marginTop: 30, color: '#7a6800', fontWeight: "bold", backgroundColor: 'white' } } variant={ 'contained' }>Go to your events</Button>
                            <Button onClick={ () => { navigate( "/events/current" ); } } style={ { marginBottom: "3vh", marginTop: 30, backgroundColor: '#7a6800', fontWeight: "bold", } } variant={ 'contained' }>Current events</Button>

                        </>

                        :
                        <>
                            <Typography sx={ { fontSize: "inherit", color: '#7a6800' } } >Hello { `${ emoji } Join us or login below !` }  </Typography>

                            <Button onClick={ () => { navigate( "/create" ); } } style={ { marginTop: 30, color: '#7a6800', fontWeight: "bold", backgroundColor: 'white' } } variant={ 'contained' }>Register now</Button>
                            <Button onClick={ () => { navigate( "/login" ); } } style={ { marginBottom: "3vh", marginTop: 30, backgroundColor: '#7a6800', fontWeight: "bold", } } variant={ 'contained' }>Log in</Button>

                        </>
                    }
                </div>

            </header>

        </div>
    );
}

export default Main;
