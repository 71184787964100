const environment = process.env.REACT_APP_ENV || 'dev';

const ROUTES = {
    EVENTS_REGISTER: "events/register/",

};
const FEXTRALIFE_URL = "https://eldenring.wiki.fextralife.com/";
//todo UPDATE VAL WHEN SERVER ONLINE

interface AppConfig {
    [ key: string ]: {
        API_URL: string;
        ROUTES: Routes;
        FEXTRALIFE_URL: string;
    };
}
interface Routes {
    EVENTS_REGISTER: string;

}
export function config ( env = environment ) {
    const val: AppConfig = {
        'dev': {
            API_URL: "https://eldenevent-api-git-dev-dekharen.vercel.app/v1/",
            ROUTES,
            FEXTRALIFE_URL
        },
        'local': {
            API_URL: "http://localhost:3001/v1/",
            ROUTES,
            FEXTRALIFE_URL
        },
        'production': {
            API_URL: "https://eldenevent-api.vercel.app/v1/",
            ROUTES,
            FEXTRALIFE_URL

        }
    };
    return val[ env ];

}
// const appConfig = config();

export default config();