// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";
import { CollectionReference, Firestore, getDoc, getFirestore, collection, getDocs, setDoc, doc, query, orderBy, Timestamp, addDoc, updateDoc, } from "firebase/firestore";
import type { QueryFieldFilterConstraint } from "firebase/firestore";
import { _Users, _Events } from "./constants";
import axios from "axios";
import appConfig from "./config";
// TODO: Add SDKs for Firebase products that you want to use
// TODO: Add timestamps & timestamp sorting
// TODO: Change for initializeAuth over getAuth() 
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAjRHC5an-jOpypUiG0Fz9A48E9haWMtCI",
    authDomain: "elden-events.firebaseapp.com",
    projectId: "elden-events",
    storageBucket: "elden-events.appspot.com",
    messagingSenderId: "210816204333",
    appId: "1:210816204333:web:dca26515b9d1dc49fe82a4",
    measurementId: "G-1VZWBB2HYL"
};


// Initialize Firebase
const app = initializeApp( firebaseConfig );
// const analytics = getAnalytics( app );
const db = getFirestore( app );
export const auth = getAuth( app );
auth.useDeviceLanguage();
// export const add = async ( { boss, discordname, username } ) => {

//     try
//     {
//         const docRef = await addDoc( collection( db, "boss-participation" ), {
//             boss,
//             discordname,
//             username,
//             completed: false,
//             timestamp: Date.now(),
//             bossCompleted: false,
//             isKiller: false,
//         } );
//         console.log( "Document written with ID: ", docRef.id );
//     } catch ( e )
//     {
//         console.error( "Error adding document: ", e );
//     }
// };

// export const querySnapshot = async () => await getDocs( collection( db, "boss-participation" ) );
// export const hide = ( ref: DocumentReference<DocumentData> ) => updateDoc( ref, { completed: true } );
// export const finished = ( ref: DocumentReference<DocumentData> ) => updateDoc( ref, { bossCompleted: true } );
// export const killed = ( ref: DocumentReference<DocumentData> ) => updateDoc( ref, { isKiller: true } );


export default db;


class FirestoreBase {
    constructor ( db: Firestore ) {
        this.database = db;
    }
    //todo try to implement readonly and see if it works
    protected database: Firestore;


}
class Documents extends FirestoreBase {
    constructor ( name: string ) {
        super( db );
        this.name = name;
    }
    name: string;
    public collection ( optionalName?: string ): CollectionReference<any> {
        return collection( this.database, optionalName ? optionalName : this.name );
    }



}
interface EventConstructor {
    uid: string;
    name: string;
    startTime: { seconds: number, nanoseconds: number; };
    bossList: Array<BossFight>;
    finished: boolean;
    about: string;
    organizerName: string;
    organizerUid: string;
    currentIndex: number;
}
export class Event extends Documents {
    uid: string;
    eventName: string;
    startTime: Timestamp;
    bossList: Array<BossFight>;
    finished: boolean;
    organizerUid: string;
    organizerName: string;
    about: string;
    currentIndex: number;
    constructor ( { uid, name, startTime, bossList, finished, about, organizerName, organizerUid, currentIndex }: EventConstructor ) {
        super( _Events );
        this.uid = uid;
        this.eventName = name;
        this.startTime = new Timestamp( startTime.seconds, startTime.nanoseconds );
        this.bossList = bossList;
        this.finished = finished;
        this.about = about;
        this.organizerName = organizerName;
        this.organizerUid = organizerUid;
        this.currentIndex = currentIndex;

    }
    public get time () {
        return new Intl.DateTimeFormat( undefined, { dateStyle: "long", timeStyle: "long" } ).format( this.startTime.toDate() );
    }
    public docInstance ( uid: string = this.uid ) {
        return doc( this.database, this.collection().path + "/" + uid );
    };

    public get eventLiteral () {
        return { name: this.eventName, startTime: this.startTime, bossList: this.bossList, finished: this.finished, organizerUid: this.organizerUid, organizerName: this.organizerName, about: this.about, currentIndex: this.currentIndex };
    }
    async createEventForServer () {
        if ( this.uid )
        {
            throw new Error( "This event already has an UID !" );
        }

        return await addDoc( this.collection(), this.eventLiteral );
    }
    async updateEventOnServer () {
        if ( !this.uid )
        {
            throw new Error( "This event has no UID !" );
        }

        return await updateDoc( this.docInstance(), this.eventLiteral );
    }
    static async getEvents ( where?: Array<QueryFieldFilterConstraint | null | undefined>, keys?: Array<string> ) {
        if ( keys )
        {

            const refs = keys.map( id => doc( db, `events/${ id }` ) );
            const values = refs.map( ref => getDoc( ref ) );
            return ( ( await Promise.all( values ) ).map( _event => new Event( { uid: _event.id, ..._event.data() } as EventConstructor ) ) || [] );
        }
        if ( !where )
        {
            where = [];
        }
        // where( "finished", "==", false )
        const q = query( collection( db, "events" ), ...where, orderBy( "startTime", "desc" ) );
        const querySnapshot = await getDocs( q );
        const events: Array<Event> = [];

        querySnapshot.forEach( serverEvent => {
            const uid = serverEvent.id;
            const data = { uid, ...serverEvent.data() } as EventConstructor;
            const event = new Event( data );
            events.push( event );
        } );
        return events;
        // const firebaseUser = ( await getDoc( ref ) ).data() as UserConstructor;
        // return new UserProfile( firebaseUser );
    }


}
interface bodyExpectedValuesRegisterEvent { bossName: string, characterName: string, displayName?: string, region: string, link: string; }
// interface bodyExpectedValuesRemoveRegister { bossName: string; }

export interface BossFight {
    // uid: string;
    done: boolean;
    currentOrder: number; //todo use to sort
    name: string;
    region?: string;
    link?: string;
    participants: Array<Participant>;
}
export interface Participant {
    attempts: number;
    characterName: string;
    displayName: string;
    killed: boolean;
    // order: number;
    uid: string; //! User UID
}
interface Boss {
    characterName: string;
    bossName: string;
    killed: boolean;
}
export interface eventsAttended {
    [ eventUid: string ]: Array<Boss>;
}
export interface userObjectLiteral {
    uid: string;
    displayName: string;
    discord: string;
    eventsAttended: eventsAttended;
    favourite_boss: string;
    characters: string[];
    about: string;
    isAdmin: boolean;
}
export class UserProfile extends Documents {
    uid: string;
    displayName: string;
    discord: string;
    eventsAttended: eventsAttended;
    characters: Array<string>;
    favourite_boss: string;
    about: string;
    readonly isAdmin: boolean;
    public get profile () {
        const literal: userObjectLiteral = { isAdmin: this.isAdmin, uid: this.uid, displayName: this.displayName, discord: this.discord, eventsAttended: this.eventsAttended, favourite_boss: this.favourite_boss, characters: this.characters, about: this.about };
        return literal;
    }
    public get docInstance () {
        return doc( this.database, this.collection().path + "/" + this.uid );
    };
    // public set boss ( v: string ) {
    //     this.favourite_boss = v;
    // }
    async token () {
        return await auth.currentUser.getIdToken();
    }
    async createFirestoreProfile () {
        const profile = this.profile;
        return await setDoc( this.docInstance, profile );
    }
    async updateFirestoreProfile ( { favourite_boss = this.favourite_boss, displayName = this.displayName, discord = this.discord, characters = this.characters, about = this.about }: { favourite_boss?: string, displayName?: string, discord?: string, characters?: Array<string>, about?: string; } ) {
        const profileUpdate = { favourite_boss, displayName, discord, characters, about };
        await setDoc( this.docInstance, profileUpdate, { merge: true } );


        return new UserProfile( { ...this.profile, ...profileUpdate } );


    }
    static async getUserFromServer ( uid: string ) {
        console.log( "calling server for ", uid );
        const ref = doc( db, `users/${ uid }` );
        const firebaseUser = ( await getDoc( ref ) ).data() as UserConstructor;
        if ( !firebaseUser )
        {
            throw new Error( "This user does not exist." );
        }
        return new UserProfile( firebaseUser );
    }

    async addEventParticipation ( eventUid: string, body: bodyExpectedValuesRegisterEvent ) {
        body.displayName = this.displayName;
        //? This method adds the data to both the user, and the event itself
        const token = await this.token();
        let config = {
            headers: {
                authorization: token,
            }
        };
        return await axios.post( appConfig.API_URL + appConfig.ROUTES.EVENTS_REGISTER + eventUid, body, config );
    }

    async removeEventParticipation ( eventUid: string, bossName: string ) {
        //? This method adds the data to both the user, and the event itself
        const token = await this.token();
        let config = {
            headers: {
                authorization: token,
            }
        };
        return await axios.delete( appConfig.API_URL + appConfig.ROUTES.EVENTS_REGISTER + eventUid + "/" + bossName, config );
    }


    async updateEventParticipation ( eventUid: string, body: bodyExpectedValuesRegisterEvent ) {
        body.displayName = this.displayName;
        //? This method adds the data to both the user, and the event itself
        const token = await this.token();
        let config = {
            headers: {
                authorization: token,
            }
        };
        return await axios.put( appConfig.API_URL + appConfig.ROUTES.EVENTS_REGISTER + eventUid, body, config );
    }

    constructor ( { uid, displayName, discord = "", eventsAttended = {}, favourite_boss = "", characters = [], isAdmin = false, about = "" }: UserConstructor ) {
        super( _Users );
        this.displayName = displayName;
        this.discord = discord;
        this.uid = uid;
        this.eventsAttended = eventsAttended;
        this.favourite_boss = favourite_boss;
        this.isAdmin = isAdmin;
        this.characters = characters;
        this.about = about;
    }

}
