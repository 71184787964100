import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QueryFieldFilterConstraint, where } from 'firebase/firestore';
import { BossFight, Event, Participant, UserProfile } from '../firebase';
import { AuthContext } from '../router/Root';
import { Avatar, Button, Card, CardHeader, Chip, Collapse, Divider, Paper, Skeleton, Snackbar, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import MoreVert from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ApiIcon from '@mui/icons-material/Api';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { User } from 'firebase/auth';
import ConfirmationDialog, { ParticipationDialog } from '../components/Dialog';
import Box from '@mui/system/Box';
import { AxiosResponse } from 'axios';
import { Alert } from '../components/Alert';
import config from '../config';
import Sadge from "./sadge.png";
type LocalParticipationUpdate = { eventUid: string, characterName?: string, bossName: string, response: AxiosResponse<any, any>; };

type TS_PARTICIPATION_TYPES = {
    REMOVE_PARTICIPATION: "REMOVE_PARTICIPATION",
    ADD_PARTICIPATION: "ADD_PARTICIPATION",
    UPDATE_PARTICIPATION: "UPDATE_PARTICIPATION";
};
type _PRT = "REMOVE_PARTICIPATION" | "ADD_PARTICIPATION" | "UPDATE_PARTICIPATION";
const _PARTICIPATION_TYPES: TS_PARTICIPATION_TYPES = {
    REMOVE_PARTICIPATION: "REMOVE_PARTICIPATION",
    ADD_PARTICIPATION: "ADD_PARTICIPATION",
    UPDATE_PARTICIPATION: "UPDATE_PARTICIPATION"
};
const dataContext = createContext( { onParticipationUpdate: ( type, dataUpdate, setDataOnParticipation ) => { }, data: [] } );
type functionParticipation = ( type: _PRT, dataUpdate: LocalParticipationUpdate, setDataOnParticipation: ( prev: any ) => any ) => void;
const EventView = () => {
    const { loaded, profile, user, propagateProfile } = useContext( AuthContext );
    const params = useParams();
    const [ data, setData ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ notifyUpdate, setNotifyUpdate ] = useState<"idle" | "success" | "failure">( "idle" );
    const [ message, setMessage ] = useState<string>( "" );
    const alertType = notifyUpdate === "failure" ? "error" : "success";
    const showMessage = notifyUpdate !== 'idle';
    const onParticipationUpdate: functionParticipation = ( type: _PRT, dataUpdate: LocalParticipationUpdate, setDataOnParticipation: ( prev: any ) => any ) => {
        const { response, eventUid, ...rest } = dataUpdate;
        if ( !eventUid )
        {
            return;
        };
        let propagation = ( prevState: UserProfile ) => prevState;
        setData( setDataOnParticipation );
        switch ( response.status )
        {
            case 200:
                // setLoading( true );
                setNotifyUpdate( 'success' );
                switch ( type )
                {
                    case ( _PARTICIPATION_TYPES.UPDATE_PARTICIPATION ):
                        setMessage( "Your changes were properly saved. Good luck !" );
                        propagation = ( prev: UserProfile ) => {
                            const bossIndex = prev.eventsAttended[ eventUid ].findIndex( ( val ) => val.bossName === dataUpdate.bossName );
                            prev.eventsAttended[ eventUid ][ bossIndex ].characterName = rest.characterName;
                            return prev;
                        };

                        break;
                    case ( _PARTICIPATION_TYPES.ADD_PARTICIPATION ):
                        setMessage( "Your participation was registered. Good luck, and don't you dare go hollow !" );

                        propagation = ( prev: UserProfile ) => {
                            if ( !prev.eventsAttended[ eventUid ] )
                            {
                                prev.eventsAttended[ eventUid ] = [];
                            }
                            prev.eventsAttended[ eventUid ].push( { characterName: prev.characters[ 0 ], ...rest, killed: false } );
                            // prev.eventsAttended[ eventUid ][ bossIndex ].characterName = rest.characterName;
                            return prev;
                        };
                        break;

                    case ( _PARTICIPATION_TYPES.REMOVE_PARTICIPATION ):
                        setMessage( "It's sad to see you go, but your participation was successfully removed." );
                        propagation = ( prev: UserProfile ) => {
                            if ( prev.eventsAttended[ eventUid ].length === 1 )
                            {
                                delete prev.eventsAttended[ eventUid ];
                                //exists
                            }
                            else
                            {
                                const bossIndex = prev.eventsAttended[ eventUid ].findIndex( ( val ) => val.bossName === dataUpdate.bossName );

                                prev.eventsAttended[ eventUid ].splice( bossIndex, 1 );
                                //( { characterName: prev.characters[ 0 ], ...rest, killed: false } 

                            }
                            // prev.eventsAttended[ eventUid ][ bossIndex ].characterName = rest.characterName;
                            return prev;
                        };

                        break;
                    default:
                        break;
                }
                break;

            default:
                setNotifyUpdate( 'failure' );
                setMessage( "An error occured. Try again later or contact the developer." );

                break;
        }
        propagateProfile( propagation );
        setLoading( false );
    };

    const handleClose = () => {
        setNotifyUpdate( 'idle' );
    };

    useEffect( () => {
        // setLoading( true );
        // setView( true );
        // setTimeout( () => {
        //     setView( false );
        // }, 2000 );
        if ( loaded )
        {
            let whereQuery: QueryFieldFilterConstraint[] | undefined;
            let keys: string[] | undefined;
            const time = new Date( Date.now() );
            switch ( params?.type )
            {
                case "upcoming":
                    whereQuery = [ where( "startTime", ">", time ) ];

                    break;
                case "current":
                    whereQuery = [ where( "startTime", "<", time ), where( "finished", "==", false ) ];

                    break;
                case "participating":
                    keys = Object.keys( profile?.eventsAttended );
                    break;
                default:
                    whereQuery = [ where( "startTime", ">", time ) ];

                    break;
            }
            Event.getEvents( whereQuery, keys ).then( data => {
                setData( data );
                setLoading( false );
            } );
        }

    }, [ loaded, profile, params, params?.type ] );
    const paperSx = { backgroundColor: "#282c34", filter: "brightness( 130%)", borderRadius: 6, };
    return (
        <dataContext.Provider value={ { data, onParticipationUpdate } }>
            <div className="App">
                <div className="App-background">
                    <Paper className={ "event-container" } elevation={ 20 } sx={ paperSx } id="eventPaper">
                        {

                            loading ? Array( data.length || 1 ).fill( null ).map( ( _el, i ) =>
                                <Box key={ i } sx={ { padding: 1 } }>
                                    <Skeleton variant="rounded" sx={ { bgcolor: '#041425' } }>

                                        <CustomCard eventIndex={ 0 } loading={ true } user={ user } profile={ profile } />
                                    </Skeleton>
                                </Box>
                            ) :
                                ( !loading ) && data[ 0 ] ? data.map( ( event, eventIndex ) => {

                                    return (
                                        <Box key={ event?.uid || eventIndex } sx={ { margin: "1vh 1vw" } }>
                                            <CustomCard key={ event?.uid + "CustomCard" || eventIndex + 'CustomCard' } loading={ loading } event={ event } user={ user } profile={ profile } eventIndex={ eventIndex } />
                                        </Box>
                                    );

                                } ) :
                                    <div>

                                        <img alt="Sadge" src={ Sadge }>
                                        </img>
                                        <p style={ { color: "darkgoldenrod" } }>Looks like there are no events yet...</p>
                                    </div>
                        }



                    </Paper>

                </div>
            </div>
            <Snackbar
                open={ showMessage }
                autoHideDuration={ 6000 }
                onClose={ handleClose }

            >

                <Alert onClose={ handleClose } severity={ alertType } sx={ { width: '100%' } } >{ message }</Alert>
            </Snackbar>

        </dataContext.Provider> );
};

export default EventView;
export const CustomCard = ( { loading = true, event, user, profile, eventIndex }: { loading: boolean, event?: Event, user: User, profile: UserProfile, eventIndex: number; } ) => {
    const isLoaded = !loading;
    const [ expanded, setExpanded ] = useState( false );
    const [ expansion, setExpansion ] = useState( false );

    const theme = useTheme();
    const q = useMediaQuery( theme.breakpoints.up( "md" ) );
    useEffect( () => {
        if ( expansion )
        {
            setTimeout( () => {


                setExpanded( true );
            }, q ? 1000 : 50 );
        }
        if ( !expansion )
        {
            // setTimeout( () => {


            setExpanded( false );
            // }, 1000 );
        }

    }, [ expansion ] );

    const transition = "width 0.75s";
    const cardSx = { width: expansion || !q ? "90vw" : "25vw", transition, height: "100%", margin: 0, backgroundColor: "#041425", filter: "brightness( 130%)", color: "white", border: event.finished ? "3px solid #05241B" : "", display: "grid" };
    // const string = new Date( 1674925200 ).toLocaleDateString();

    const handleExpandClick = () => {
        setExpansion( !expansion );
    };
    const scrollRef = useRef( null );
    return (

        <Card sx={ cardSx } raised>
            <CardHeader

                //todo : add version where in sm it goes to full column
                sx={ { subheader: { color: "white", } } }
                subheaderTypographyProps={ { color: '#7a6800' } }
                titleTypographyProps={ { color: '#7a6800', fontSize: "inherit" } }

                avatar={
                    <Avatar sx={ { bgcolor: "#041425", filter: "brightness( 50%)" } } aria-label={ event?.organizerName }>
                        { isLoaded ? event?.organizerName?.[ 0 ] : "D" }
                    </Avatar>
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVert />
                    </IconButton>
                }
                title={ isLoaded ? event?.eventName : "This is the event title" }
                subheader={ isLoaded ? event?.time : "This is the event time" }
            />
            <CardContent>
                <Typography variant="body1" color="white" fontStyle="italic" fontWeight={ "lighter" } >

                    { isLoaded ? `Organized by ${ event?.organizerName } ` : "Organizer" }
                </Typography>
                <Typography variant="body1" color="goldenrod" fontStyle="italic" fontWeight={ "lighter" } >

                    { isLoaded && event?.finished ? 'This event is over. You can still check out the attempts below !' : isLoaded ? `Currently fighting ${ event.bossList[ event.currentIndex ? event.currentIndex : 0 ] ? event.bossList[ event.currentIndex ? event.currentIndex : 0 ].name : "a sideboss" }` : "Current bossfight" }
                </Typography>
                <Typography variant="body2" color="#7a6800" style={ { whiteSpace: 'pre-line' } }>

                    { isLoaded ? event?.about : "You can register here \n Good luck !" }
                </Typography>
            </CardContent>



            <CardActions sx={ { justifyContent: "space-between", alignSelf: "flex-end" } }>

                <Button variant='contained' sx={ {
                    color: expanded ? "#7a6800" : "#041425", fontWeight: "bold", backgroundColor: expanded ? "#541212" : "#7a6800",
                    filter: "brightness(60%)",

                } } onClick={ handleExpandClick }>{ expanded ? 'see less' : 'see more' }</Button>
                <ExpandMore
                    expand={ expanded }
                    onClick={ handleExpandClick }
                    aria-expanded={ expanded }
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={ expanded } timeout={ 'auto' } unmountOnExit onEntered={ () => {

                scrollRef.current?.scrollIntoView( { behavior: "smooth", block: "end" } );
            } }>
                <Divider variant="middle" ><Chip sx={ { color: "white", label: { color: "white" } } } label="Boss List" /></Divider>

                <CardContent ref={ scrollRef } sx={ { display: "flex", flexDirection: q ? "row" : "column", width: "100%", padding: 0, flexWrap: "wrap", justifyContent: "center", alignContent: "center" } } >
                    { event?.bossList?.map( ( boss, index ) => {

                        return (
                            <BossCard event={ event } key={ `bossCard-${ boss.name }-${ eventIndex }-${ index }` } eventIndex={ eventIndex } boss={ boss } index={ index } isLoaded={ isLoaded } user={ user } profile={ profile } />

                        );
                    } ) }
                </CardContent>
            </Collapse>

        </Card>

    );
};
export const BossCard = ( { boss, event, index, isLoaded, user, profile, eventIndex }: { event: Event, boss: BossFight, index: number, isLoaded: boolean, user: User, profile: UserProfile, eventIndex: number; } ) => {
    const navigate = useNavigate();
    const { onParticipationUpdate } = useContext( dataContext );
    const eventUid = event.uid;
    const scrollRef = useRef( null );
    const [ expanded, setExpanded ] = useState( false );
    const bossName = boss.name;
    // const { region, link } = boss;  //todo Add to user profile ? Any need ?
    const createParticipation = async ( characterName: string ) => {
        const setDataOnParticipation = ( state ) => {
            state[ eventIndex ].bossList[ index ].participants.push( { killed: false, characterName: characterName, attempts: 0, displayName: profile.displayName, uid: profile.uid } );
            return [ ...state ];
        };

        const { region, link } = boss;
        const response = await profile.addEventParticipation( eventUid, { characterName, bossName, region, link } );
        onParticipationUpdate( _PARTICIPATION_TYPES.ADD_PARTICIPATION, { eventUid, characterName, bossName, response }, setDataOnParticipation );
    };




    const content = !boss.participants[ 0 ] ?
        <Typography>No one has taken on this challenge yet. Be the <strong>first</strong> to register !</Typography>
        :
        boss.done ?
            <Typography>The last person to fight this boss was <strong> { ( boss.participants[ boss.currentOrder ] || boss.participants[ 0 ] ).displayName } </strong> with the character <strong>{ ( boss.participants[ boss.currentOrder ] || boss.participants[ 0 ] ).characterName }.</strong>
            </Typography>
            :
            <Typography>The current person registered to fight is <strong> { ( boss.participants[ boss.currentOrder ] || boss.participants[ 0 ] ).displayName }</strong> with the character <strong>{ ( boss.participants[ boss.currentOrder ] || boss.participants[ 0 ] ).characterName }.</strong>
            </Typography>;
    const handleExpandClick = () => {
        setExpanded( !expanded );
        // console.log( "scrollref", scrollRef );
        // scrollTo
        // scrollRef.current?.scrollIntoView( true );
    };
    const theme = useTheme();

    const q = useMediaQuery( theme.breakpoints.up( "md" ) );

    return (

        <Box sx={ { margin: "1vh 1vw", width: q && !expanded ? "30%" : "900%", maxWidth: q && !expanded ? "30%" : "90%" } }>

            <Card raised sx={ { display: "flex", flexDirection: "column", bgcolor: "#041425", filter: "brightness( 150%)", width: "100%", maxWidth: "100%", minHeight: "100%", border: boss.done ? "1px solid #292300" : "" } }>
                <CardHeader
                    sx={ { paddingBottom: 0 } }
                    title={ isLoaded ? boss.name : "This is the boss's name" }
                    titleTypographyProps={ { fontSize: "inherit", fontWeight: "bold" } }
                // subheaderTypographyProps={ { sx: { color: "#7a6800", fontStyle: "italic" } } }
                >
                    {/* <Typography>{ boss.name } { "" + q }</Typography> */ }
                </CardHeader>
                <Box>

                    <Divider flexItem variant="middle" sx={ { marginBottom: 3 } } >
                        <Chip label={ isLoaded && boss?.region ? boss?.region : "Region" } sx={ { color: "#524500", fontStyle: "italic", fontWeight: "bold" } } />
                    </Divider>
                </Box>
                <CardContent>

                    { content }
                    { boss?.link && <Button variant={ "outlined" } href={ config.FEXTRALIFE_URL + boss.link } target="_blank" >see boss wiki</Button> }

                </CardContent>
                <CardActions sx={ { justifyContent: "space-between", marginTop: "auto" } } >
                    { user && profile && !boss.participants.some( ( e ) => e.uid === user.uid ) && !event.finished && <ParticipationDialog options={ profile.characters } defaultValue={ null } onUpdate={ ( val ) => { createParticipation( val ); } } /> }
                    { !user && <Button sx={ { color: "black", fontWeight: "bold" } }
                        variant={ "contained" }
                        aria-controls="not-connected-go-login"
                        aria-label="go to login page"
                        onClick={ () => navigate( "/login" ) }
                    >
                        Login to participate
                    </Button> }
                    <ExpandMore

                        expand={ expanded }
                        onClick={ handleExpandClick }
                        aria-expanded={ expanded }
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </CardActions>
                <Collapse in={ expanded } timeout="auto" unmountOnExit onEntered={ () => {

                    scrollRef.current?.scrollIntoView( { behavior: "smooth", block: "center" } );
                } } >
                    <Divider variant="middle" ><Chip label="Participants" /></Divider>
                    <CardContent
                        ref={ scrollRef }

                        sx={ { display: "flex", flexDirection: q ? "row" : "column", alignContent: q ? "flex-start" : "center", justifyContent: q ? "flex-start" : "center", flexWrap: "wrap" } } >
                        { boss.participants.map( ( participant, i ) => {
                            return <ParticipantCard eventIndex={ eventIndex } event={ event } boss={ boss } key={ participant.characterName + participant.displayName + i } i={ i } isLoaded={ isLoaded } user={ user } participant={ participant } index={ index } profile={ profile } />;
                        } ) }
                    </CardContent>
                </Collapse>
            </Card>
        </Box>
    );
};
export const ParticipantCard = ( { participant, index, isLoaded, user, profile, boss, event, eventIndex, i }: { i: number, event: Event, eventIndex: number, boss: BossFight, profile: UserProfile, participant: Participant, index: number, isLoaded: boolean, user: User; } ) => {
    const { onParticipationUpdate } = useContext( dataContext );
    const navigate = useNavigate();
    const bossName = boss.name;
    const eventUid = event.uid;
    const { region, link } = boss;
    const theme = useTheme();

    const q = useMediaQuery( theme.breakpoints.up( "md" ) );
    const updateParticipation = async ( characterName: string ) => {
        const response = await profile.updateEventParticipation( eventUid, { characterName, bossName, region, link }, );
        // ctx.onParticipationUpdate
        const setDataOnParticipation = ( state ) => {
            let val = state[ eventIndex ].bossList[ index ].participants[ i ];
            state[ eventIndex ].bossList[ index ].participants[ i ] = { ...val, characterName, displayName: profile.displayName };
            return [ ...state ];
        };

        onParticipationUpdate( _PARTICIPATION_TYPES.UPDATE_PARTICIPATION, { eventUid, characterName, bossName, response }, setDataOnParticipation );



    };
    const unregister = async () => {
        const response = await profile.removeEventParticipation( eventUid, bossName );
        const setDataOnParticipation = ( state ) => {
            state[ eventIndex ].bossList[ index ].participants.splice( i, 1 );
            return [ ...state ];
        };
        onParticipationUpdate( _PARTICIPATION_TYPES.REMOVE_PARTICIPATION, { eventUid, bossName, response }, setDataOnParticipation );

    };
    const sxValue = participant.killed ? { display: "flex", width: q ? '24vw' : "70vw", flexDirection: "column", bgcolor: "#041425", filter: "brightness( 150%)", border: "1px solid #292300", marginX: "1vw", marginY: "1vh" } : { display: "flex", width: q ? '24vw' : "70vw", flexDirection: "column", bgcolor: "#041425", filter: "brightness( 150%)", marginX: "1vw", marginY: "1vh" };
    return ( <Card raised sx={ sxValue }>
        <CardHeader
            title={ isLoaded ? participant.displayName : "This is the user name" }
        >
        </CardHeader>
        <Box>

            <Divider>{ participant.killed ? <ApiIcon color='error' /> : i === boss.currentOrder ? <ApiIcon color='success' /> : <ApiIcon /> }</Divider>
        </Box>
        <CardContent>
            <Typography>Attempts : { participant.attempts }</Typography>
            <Typography>{ participant.killed ? `Congratulations on the kill ${ participant.displayName } !` : 'This user has yet to kill the boss.' }</Typography>


            <Typography>Character : { participant.characterName }</Typography>
        </CardContent>
        <CardActions sx={ { justifyContent: "center" } } >
            { user?.uid === participant.uid ?
                <>
                    <ConfirmationDialog options={ profile.characters } defaultValue={ participant.characterName } onUpdate={ ( updatedName ) => updateParticipation( updatedName ) } onUnregister={ () => { unregister(); } } />
                    <Button variant='outlined' onClick={ () => navigate( '/profile' ) } sx={ { color: "#7a6800", filter: 'brightness(50%)', fontWeight: 'bold' } } color='warning'>go to profile</Button>
                </> :
                <Button variant='outlined' onClick={ () => navigate( '/user/' + participant.uid ) } color={ "warning" } sx={ { color: "#7a6800", filter: 'brightness(50%)', fontWeight: 'bold' } } >see profile</Button>
                // <Button color={ "secondary" } sx={ { color: "black", fontWeight: "bold" } } variant={ "contained" }>Change participation</Button> 
            }

        </CardActions>
    </Card> );
};

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}
const ExpandMore = styled( ( props: ExpandMoreProps ) => {
    const { expand, ...other } = props;
    return <IconButton { ...other } />;
} )( ( { theme, expand } ) => ( {
    border: "1px solid black",
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    filter: "brightness(60%)",
    backgroundColor: expand ? "#041425" : "#7a6800",
    marginLeft: 'auto',
    transition: theme.transitions.create( 'transform', {
        duration: theme.transitions.duration.shortest,
    } ),
} ) );
