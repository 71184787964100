import React from 'react';
import Button from '@mui/material/Button';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

export interface ConfirmationDialogRawProps {
    id: string;
    keepMounted: boolean;
    value: string;
    open: boolean;
    options: Array<string>;
    onClose: ( value?: string ) => void;
    onUnregister?: () => void;
}

function ConfirmationDialogRaw ( props: ConfirmationDialogRawProps ) {
    const { options, onClose, onUnregister, value: valueProp, open, ...other } = props;
    const [ value, setValue ] = React.useState( valueProp );
    const radioGroupRef = React.useRef<HTMLElement>( null );

    React.useEffect( () => {
        if ( !open )
        {
            setValue( valueProp );
        }
    }, [ valueProp, open ] );

    const handleEntering = () => {
        if ( radioGroupRef.current != null )
        {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };
    const handleUnregister = () => {
        onUnregister();
        onClose();
    };

    const handleOk = () => {
        onClose( value );
    };

    const handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setValue( ( event.target as HTMLInputElement ).value );
    };

    return (
        <Dialog
            sx={ { '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } } }
            maxWidth="xs"
            TransitionProps={ { onEntering: handleEntering } }
            open={ open }
            { ...other }
        >
            <DialogTitle>Choose your character</DialogTitle>
            {
                options && options[ 0 ] ?

                    <DialogContent dividers>
                        <RadioGroup
                            ref={ radioGroupRef }
                            aria-label="characters"
                            name="characters"
                            value={ value }
                            onChange={ handleChange }
                        >
                            { options.map( ( option ) => (
                                <FormControlLabel
                                    value={ option }
                                    key={ option }
                                    control={ <Radio /> }
                                    label={ option }
                                />
                            ) ) }
                        </RadioGroup>
                    </DialogContent>
                    : <DialogContent>
                        <Typography>You need to specify characters first ! Head to your profile.</Typography>
                    </DialogContent>
            }
            <DialogActions>
                { onUnregister &&
                    <Button variant={ "outlined" } color={ "error" } onClick={ handleUnregister }>Remove participation</Button>
                }
                {
                    options && options[ 0 ] &&

                    <Button variant={ "outlined" } color={ "success" } onClick={ handleOk }>Confirm</Button> }
                <Button variant={ "outlined" } autoFocus onClick={ handleCancel }>
                    Cancel
                </Button>

            </DialogActions>
        </Dialog>
    );
}

const ConfirmationDialog = ( { options, defaultValue, onUnregister, onUpdate }: { options: Array<string>, defaultValue: string | null | undefined, onUnregister: () => void, onUpdate: ( newValue: string ) => void; } ) => {
    // const {options, default} = props;
    const [ open, setOpen ] = React.useState( false );
    const [ value, setValue ] = React.useState( defaultValue || options[ 0 ] );

    const handleClickListItem = () => {
        setOpen( true );
    };

    const handleClose = ( newValue?: string ) => {
        setOpen( false );

        if ( newValue )
        {
            setValue( newValue );
            onUpdate( newValue );
        }
    };

    //value = chosen value
    return (
        <>
            <Button
                sx={ { color: "black", fontWeight: "bold", filter: "brightness( 30%)", backgroundColor: '#7a6800' } }

                variant={ 'contained' }
                aria-controls="choice-menu"
                aria-label="character selection"
                onClick={ handleClickListItem } >
                Modify participation
            </Button>
            <ConfirmationDialogRaw
                onUnregister={ onUnregister }
                options={ options }
                id="characterchoice-menu"
                keepMounted
                open={ open }
                onClose={ handleClose }
                value={ value }
            />
        </>
    );
};


export const ParticipationDialog = ( { options, defaultValue, onUpdate }: { options: Array<string>, defaultValue: string | null | undefined, onUpdate: ( newValue: string ) => void; } ) => {
    // const {options, default} = props;
    const [ open, setOpen ] = React.useState( false );
    const [ value, setValue ] = React.useState( defaultValue || options[ 0 ] );

    const handleClickListItem = () => {
        setOpen( true );
    };

    const handleClose = ( newValue?: string ) => {
        setOpen( false );

        if ( newValue )
        {
            setValue( newValue );
            onUpdate( newValue );
        }
    };

    //value = chosen value
    return (
        <>
            <Button
                sx={ { color: "black", fontWeight: "bold" } }
                variant={ "contained" }
                aria-controls="choice-menu"
                aria-label="character selection"
                onClick={ handleClickListItem }
            >
                Participate
            </Button>
            <ConfirmationDialogRaw
                options={ options }
                id="characterchoice-menu"
                keepMounted
                open={ open }
                onClose={ handleClose }
                value={ value }
            />
        </>
    );
};
export default ConfirmationDialog;