import { Button, Chip, Divider, Grid, Paper, Skeleton, TextField } from '@mui/material';
import { doc, runTransaction, Timestamp, where } from 'firebase/firestore';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import db, { Event } from '../firebase';
import type { BossFight } from "../firebase";
import { AuthContext } from '../router/Root';
import RenderGroup from './BossListView';
import { CustomCard } from '../events/Events';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { adminTextFieldSx } from '../constants';
import Box from '@mui/system/Box';
import madge from "./madge.png";
import { Stack } from '@mui/system';
const valueExists = ( val ) => {
    return ( val !== null && val !== undefined );
};
enum modes {
    modification,
    creation
}

// import obj from "./bosslist.json";
// const unique = {};
// const notUnique = {};
// const a = obj.value.map( e => e.bosses.map( a => ( { ...a, region: e.region } ) ) ).reduce( ( prev, next ) => [ ...prev, ...next ] );
// a.forEach( el => {
//     if ( unique[ el.name ] )
//     {
//         if (
//             notUnique[ el.name ]

//         )
//         {
//             notUnique[ el.name ].number++;
//             notUnique[ el.name ].regions.push( el.region );
//         }
//         else
//         {
//             notUnique[ el.name ] = { number: 1, regions: [ el.region ] };
//         }
//     }
//     else
//     {
//         unique[ el.name ] = { name: el.name, region: el.region };
//     }
// } );
// console.log( "unique", unique );
// console.log( "not unique", notUnique );
// console.log( Object.keys( notUnique ), Object.keys( notUnique ).length );
//TODO PREVENT NON NEEDED RERENDERS
const AdminPanel = () => {
    const { user, profile, loaded } = useContext( AuthContext );
    const params = useParams();
    //! State
    const [ data, setData ] = useState<Array<Event>>( [] );
    const [ error, setError ] = useState( false );
    // new Event()
    const [ initialLoading, setInitialLoading ] = useState( true );
    const [ selected, setSelected ] = useState<number>( null );
    const [ bosses, setBosses ] = useState<Set<string>>( new Set() );
    const [ dateWithInitialValue, setDateWithInitialValue ] = useState<Date>( new Date() );

    const [ mode, setMode ] = useState( modes.creation );
    const [ name, setName ] = useState( "" );
    const [ about, setAbout ] = useState( "" );
    const [ uid, setUid ] = useState( "" );
    const startTime = Timestamp.fromDate( dateWithInitialValue );
    const [ finished, setFinished ] = useState( false );
    const [ organizerUid, setOrganizerUid ] = useState( "" );
    const [ organizerName, setOrganizerName ] = useState( "" );
    const [ currentIndex, setCurrentIndex ] = useState( 0 );
    // const [resetData, setResetData] = useState(false)
    const dataRef = useRef<Array<Event>>( null );
    console.log( "unused data :", error );
    if ( params.something )
    {
        //  baseValue = params.something;
    }
    // let baseValue = [ where( 'startTime', "<", time ), where( "finished", "==", false ) ];

    // const [ whereValue,
    //     //todo make a branching value to handle searching for events // setwhereValue 
    // ] = useState( baseValue );
    useEffect( () => {
        console.log( "effect", user, "user" );
        if ( user )
        {

            //   const time = new Date( Date.now() );
            if ( mode === modes.modification )
            {

                let baseValue = [ where( 'organizerUid', "==", user.uid ), where( "finished", "==", false ) ];
                const setValue = async ( val = baseValue ) => {
                    try
                    {

                        const value = await Event.getEvents( val );
                        setData( value );
                        dataRef.current = [ ...value ];
                        setInitialLoading( false );
                    } catch ( err )
                    {
                        console.log( err );
                        setError( true );
                    }
                };
                setValue();
            }

        }



    }, [ user, mode ] );

    // useEffect( () => {
    //     try
    //     {

    //         const setValue = async () => {
    //             const value = await Event.getEvents( whereValue );
    //             setData( value );

    //         };

    //         setValue();

    //     } catch ( err )
    //     {
    //         console.log( err );
    //         setError( true );
    //     }


    // }, [ whereValue ] );
    interface BossObject { name: string, link: string, region: string; }
    //! State


    const navigate = useNavigate();


    const addToBossList = ( value: BossObject ) => {
        return ( prev: Set<string> ) => {
            if ( value )
            {
                const toString = {
                    done: false,
                    currentOrder: 0,
                    participants: [],
                    ...value,
                };
                const set = new Set( prev );
                set.add( JSON.stringify( toString ) );
                return set;
            } else return prev;
        };
    };
    const removeFromBoss = ( element: string ) => {
        return ( ( prev: Set<string> ) => {
            const set = new Set( prev );
            set.delete( element );
            return set;

        } );
    };
    // const newEvent = new Event({uid:null, name:"eventName", startTime:dateWithInitialValue})
    // const organizerUid = user?.uid;
    // const organizerName = user?.displayName;

    const paperSx = { backgroundColor: "#282c34", filter: "brightness( 130%)", borderRadius: 6, };


    const resetState = useCallback( () => {
        setUid( "" );
        setName( "Event Name" );
        setDateWithInitialValue( new Date() );
        setBosses( new Set() );
        setFinished( false );
        setOrganizerName( user?.displayName );
        setOrganizerUid( user?.uid );
        setAbout( "about this event" );
        setSelected( null );
    }
        , [ user ] );
    useEffect( () => {
        if ( mode === modes.creation )
        {
            console.log( "creation" );
            resetState();
        }


    }, [ loaded, mode, resetState ] );

    const createEvent = {
        currentIndex,
        uid,
        name,
        startTime,
        bossList: Array.from( bosses ).map( e => {
            const val: BossFight = JSON.parse( e );
            return val;
        } ),
        finished,
        organizerUid, organizerName, about,
    };
    const bossView = Array.from( bosses );
    const list = createEvent.bossList;
    const fightersParticipating = list[ currentIndex ]?.participants;
    const order = list[ currentIndex ]?.currentOrder;
    const currentFighter = fightersParticipating?.[ order ];
    const selectedIsNotDefined = ( selected === null || selected === undefined );
    if ( profile?.isAdmin )
    {

        return (
            <div className="App">
                {/* <div>Unauthorized</div> */ }
                <div className="App-header">
                    {/* <div>
                        { Array.from( bosses ).map( stringifiedBoss => {
                            const boss: BossObject = JSON.parse( stringifiedBoss );
                            return ( <p key={ boss.name + boss.region }>{ boss.name }</p> );
                        } )
                        }
                    </div> */}
                    <div>
                        <>
                            <form onSubmit={ async ( a ) => {

                                a.preventDefault();
                                switch ( mode )
                                {
                                    case modes.creation:
                                        try
                                        {

                                            const modifiedEvent = new Event( createEvent );
                                            await modifiedEvent.createEventForServer();
                                            //todo set success or sth (also disable button)
                                        }
                                        catch ( err )
                                        {
                                            console.error( "An error occured ! ", err );
                                            setError( true );

                                        }

                                        break;

                                    case modes.modification:
                                        try
                                        {
                                            if ( !dataRef.current )
                                            {
                                                console.log( "Reference is unavailable ! Make sure that the data is properly saved." );
                                                return;

                                            }
                                            const modifiedEvent = new Event( createEvent );
                                            const eventReference = dataRef.current[ selected ];
                                            //    const modifiedBosses =  []
                                            //    const oldBossesAmount = eventReference.bossList.length;
                                            //     const newBossesAmount = modifiedEvent.bossList.length;
                                            //     const oldBossesNames = eventReference.bossList.map(el => el.name).sort();
                                            //     const newBossesNames = modifiedEvent.bossList.map(el => el.name).sort();
                                            const referenceObject = {};
                                            modifiedEvent.bossList.forEach( ( boss, index ) => {
                                                const { name, ...rest } = boss;
                                                referenceObject[ name ] = { index, ...rest };

                                            } );
                                            const users = {};
                                            eventReference.bossList.forEach( ( boss, index ) => {
                                                const { name, participants } = boss;
                                                const newEventBoss = referenceObject[ name ];
                                                if ( !newEventBoss )
                                                {
                                                    // if the boss doesn't exist in the new event then we specify which bosses were deleted.
                                                    participants.forEach( ( part, i ) => {
                                                        if ( !users[ part.uid ] )
                                                        {
                                                            users[ part.uid ] = {};
                                                        }
                                                        const prevValue = users[ part.uid ]?.bossesDeleted || [];

                                                        users[ part.uid ].bossesDeleted = [ ...prevValue, name ];
                                                    } );
                                                } else
                                                {
                                                    //then check for differences (nothing needed user-side if nothing did change)
                                                    participants.forEach( ( part, i ) => {
                                                        const participationsArentEqual = !( JSON.stringify( part ) === JSON.stringify( newEventBoss.participants[ i ] ) );
                                                        if ( participationsArentEqual )
                                                        {
                                                            if ( !users[ part.uid ] )
                                                            {
                                                                users[ part.uid ] = {};
                                                            }
                                                            //old and new participants are not the same : we need to update the user profile to reflect that change.
                                                            const prevValue = users[ part.uid ]?.participationsChanged || {};
                                                            users[ part.uid ].participationsChanged = { ...prevValue, [ name ]: newEventBoss.participants[ i ] };
                                                        }
                                                    } );
                                                }
                                            } );
                                            const transactions: Array<Promise<any>> = [];
                                            for ( let uid in users )
                                            {
                                                const transaction = runTransaction( db, async ( t ) => {
                                                    const userDoc = doc( db, '/users/' + uid );
                                                    const userProf = ( await t.get( userDoc ) ).data();
                                                    const currentEventFromProfile = userProf.eventsAttended[ createEvent.uid ];

                                                    // if ( users[ uid ].participationsChanged )
                                                    // {
                                                    // for ( let bossName in users[ uid ].participationsChanged ){
                                                    // users[uid].participationsChanged;
                                                    let newBosses: Array<any> = currentEventFromProfile.map( bossFight => {
                                                        const correspondingParticipation = users[ uid ]?.participationsChanged?.[ bossFight.bossName ];
                                                        if ( correspondingParticipation )
                                                        {
                                                            return {
                                                                bossName: bossFight.bossName,
                                                                characterName: correspondingParticipation.characterName,
                                                                displayName: correspondingParticipation.displayName,
                                                                killed: correspondingParticipation.killed,
                                                                attempts: correspondingParticipation.attempts
                                                            };
                                                        }
                                                        else return bossFight;

                                                    } );
                                                    // }
                                                    ///
                                                    // }
                                                    if ( users[ uid ].bossesDeleted )
                                                    {

                                                        newBosses = newBosses.filter( event => {
                                                            return !( users[ uid ].bossesDeleted.includes( event.bossName ) );

                                                        } );
                                                        ///
                                                    }
                                                    const val = { [ `eventsAttended.${ createEvent.uid }` ]: newBosses };
                                                    t.update( userDoc, val );
                                                    return Promise.resolve();
                                                } );

                                                transactions.push( transaction );
                                            }
                                            console.log( users );
                                            console.log( transactions );

                                            await Promise.all( transactions );
                                            await modifiedEvent.updateEventOnServer();
                                            console.log( "success !" );
                                        }
                                        catch ( err )
                                        {
                                            console.error( "An error occured ! ", err );
                                            setError( true );
                                        }
                                        setInitialLoading( true );
                                        resetState();
                                        break;

                                    default:
                                        break;
                                }
                            } }>
                                <div style={ { display: "flex", flexDirection: "column", paddingTop: "5vh" } }>
                                    {/* <div style={ { display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" } } > */ }
                                    <Stack spacing={ 2 } direction={ 'row' } justifyContent={ 'center' } divider={ <Divider sx={ { bgcolor: "goldenrod" } } orientation="vertical" flexItem></Divider> }>

                                        <Button disabled={ mode === modes.creation } sx={ { color: "goldenrod", borderColor: "darkgoldenrod" } } variant={ "outlined" } onClick={ () => setMode( modes.creation ) } >Create</Button>
                                        <Button disabled={ mode === modes.modification } sx={ { color: "goldenrod", borderColor: "darkgoldenrod" } } variant={ "outlined" } onClick={ () => setMode( modes.modification ) }>Alter</Button>
                                    </Stack>
                                    {/* </div> */ }

                                    <Box sx={ { margin: 1 } }>

                                        <TextField sx={ { ...adminTextFieldSx, width: "50vw", } } value={ name } onChange={ ( event ) => setName( event.target.value ) } label="Event name" />
                                    </Box>
                                    <Box sx={ { margin: 1 } }>

                                        <TextField sx={ { ...adminTextFieldSx, width: "50vw", } } multiline value={ about } onChange={ ( event ) => setAbout( event.target.value ) } label="Event about" />
                                    </Box>
                                    <Box sx={ { margin: 1 } }>

                                        <LocalizationProvider dateAdapter={ AdapterDayjs }>

                                            <MobileDateTimePicker

                                                // onAccept={ ( value ) => { console.log( "is accepted !" ); } }
                                                value={ dateWithInitialValue }
                                                onChange={ ( newValue ) => {
                                                    const d = new Date( newValue );
                                                    d.setSeconds( 0 );
                                                    setDateWithInitialValue( d );
                                                } }
                                                label="Pick a date for the event"
                                                onError={ console.log }
                                                minDate={ mode === modes.creation ? new Date() : undefined }
                                                inputFormat="YYYY/MM/DD hh:mm a"

                                                // mask="____/__/__ __:__ _M"

                                                renderInput={ ( params ) => <TextField sx={ { ...adminTextFieldSx, width: "50vw", } } { ...params } /> }
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box sx={ { margin: 1, display: "flex", flexDirection: "row", justifyContent: "center", wrap: "wrap" } }>

                                        <RenderGroup onChange={ ( event, value ) => {
                                            console.log( value );
                                            setBosses( addToBossList( value ) );
                                        } } />
                                        {/* <Button sx={ { marginLeft: 1 } } variant={ "outlined" }> Add bosslist to Event</Button> */ }
                                    </Box>
                                    <Box sx={ { margin: 1, display: "flex", flexDirection: "row", justifyContent: "center", wrap: "wrap", width: "50vw", alignSelf: "center" } }>

                                        {/* <Paper sx={ { backgroundColor: "white", height: 50, width: "50%" } }> */ }
                                        { bossView[ 0 ] &&
                                            <Grid container spacing={ 0.5 } direction={ "row" } justifyContent={ 'flex-start' } sx={ { color: "darkgoldenrod", border: "1px dotted #041425", borderRadius: 3, padding: 1, paddingBottom: 2 } }>
                                                {
                                                    bossView.map( ( el, i ) => {
                                                        const boss: BossObject = JSON.parse( el );

                                                        return ( <Grid key={ "AdminChipGrid-" + i + "-" + boss.name + "-" + boss.region } item xs={ "auto" } sx={ { maxWidth: "100%" } } overflow={ "hidden" }>

                                                            <Chip onDelete={ () => setBosses( removeFromBoss( el ) ) } sx={ { color: "darkgoldenrod" } } label={ boss.name }></Chip>
                                                        </Grid>
                                                        );
                                                    } )
                                                }

                                            </Grid> }
                                        {/* </Paper> */ }
                                    </Box>

                                </div>
                                {/* // todo : this is straight up HORRIBLE to go through (event though it works). I should isolate values and make the code more readable. */ }
                                { mode === modes.modification && !initialLoading &&
                                    <div style={ { display: "flex", flexDirection: "column", flexWrap: "wrap", alignContent: "center" } }>
                                        {/* <Stack spacing={ 2 } direction={ 'row' } justifyContent={ 'center' } divider={ <Divider sx={ { bgcolor: "goldenrod" } } orientation="vertical" flexItem></Divider> }> */ }

                                        {/* </Stack> */ }


                                        {
                                            !selectedIsNotDefined && <>
                                                <div>
                                                    <div style={ { marginRight: "auto", marginLeft: "auto", padding: 10, width: "fit-content", border: "1px solid goldenrod", borderRadius: "15px" } }>
                                                        <Button sx={ { marginX: "0.75vw" } } variant="outlined" color="error" onClick={ () => setFinished( !finished ) } >{ finished ? "restart event" : "end event" }</Button>
                                                        <Button sx={ { marginX: "0.75vw" } } variant="outlined" color="info" onClick={ () => {
                                                            list[ currentIndex ].done = ( !list[ currentIndex ].done );
                                                            setBosses( new Set( list.map( e => JSON.stringify( e ) ) ) );
                                                        } } >{ createEvent.bossList[ currentIndex ].done ? "Revert boss kill" : "Mark boss as killed" }</Button>

                                                    </div>
                                                    <p style={ { color: "darkgoldenrod", textDecorationLine: "overline ", textDecorationStyle: "double" } }>
                                                        { currentFighter ? "Altering " + currentFighter.displayName + "'s participation" : bossView[ currentIndex ] ? "This boss has no participants yet." : "There are no bosses (selected) in this event." }</p>
                                                </div>
                                                <Grid container spacing={ 2 } sx={ { width: "80%" } }>
                                                    <Grid item xs={ 4 }>
                                                        <p style={ { color: "darkgoldenrod", textDecorationLine: "overline ", } }>

                                                            current boss : { ( createEvent.bossList[ currentIndex ]?.name ) ? createEvent.bossList[ currentIndex ]?.name : "None" }
                                                        </p>
                                                        {/* <Button variant="outlined" color="error" disabled={ currentIndex === 0 } onClick={ () => setCurrentIndex( ( currentIndex - 1 ) ) } >Previous boss</Button> */ }
                                                    </Grid>
                                                    <Grid item xs={ 4 }>
                                                        <p style={ { color: "darkgoldenrod", textDecorationLine: "overline ", } }>
                                                            current attempts : { ( currentFighter ) ? currentFighter.attempts : "None" }

                                                        </p>
                                                        {/* <Button variant="outlined" color="error" disabled={ currentIndex === 0 } onClick={ () => setCurrentIndex( ( currentIndex - 1 ) ) } >Previous boss</Button> */ }
                                                    </Grid>
                                                    <Grid item xs={ 4 }>
                                                        <p style={ { color: "darkgoldenrod", textDecorationLine: "overline ", } }>

                                                            participant</p>
                                                        {/* <Button variant="outlined" color="error" disabled={ currentIndex === 0 } onClick={ () => setCurrentIndex( ( currentIndex - 1 ) ) } >Previous boss</Button> */ }
                                                    </Grid>
                                                    <Grid item xs={ 4 }>

                                                        <Button variant="outlined" color="error" disabled={ currentIndex === 0 } onClick={ () => setCurrentIndex( ( currentIndex - 1 ) ) } >Previous boss</Button>
                                                    </Grid>

                                                    <Grid item xs={ 4 }>  <Divider sx={ { borderColor: "goldenrod", "&::before": { borderColor: "goldenrod" }, "&::after": { borderColor: "goldenrod" } } } orientation="horizontal" ><Chip sx={ { color: "goldenrod", border: "thin solid goldenrod" } } label="boss"></Chip> </Divider> </Grid>

                                                    <Grid item xs={ 4 }>

                                                        <Button variant="outlined" color="success" disabled={ currentIndex + 1 === bossView.length || !bossView.length } onClick={ () => {
                                                            setCurrentIndex( ( currentIndex + 1 ) );
                                                        } } >Next boss</Button>

                                                    </Grid>


                                                    {/* <Paper> */ }

                                                    <Grid item xs={ 4 }>

                                                        <Button variant="outlined" color="error" disabled={ !valueExists( order ) || order === 0 } onClick={ () => {
                                                            list[ currentIndex ].currentOrder--;
                                                            setBosses( new Set( list.map( e => JSON.stringify( e ) ) ) );
                                                        } }>Previous participant</Button>

                                                    </Grid>
                                                    <Grid item xs={ 4 }>  <Divider sx={ { borderColor: "goldenrod", "&::before": { borderColor: "goldenrod" }, "&::after": { borderColor: "goldenrod" } } } orientation="horizontal" ><Chip sx={ { color: "goldenrod", border: "thin solid goldenrod" } } label="participant"></Chip> </Divider> </Grid>

                                                    <Grid item xs={ 4 }>

                                                        <Button variant="outlined" color="success" disabled={ !valueExists( order ) || fightersParticipating?.length === 0 || order === fightersParticipating?.length - 1 } onClick={ () => {
                                                            list[ currentIndex ].currentOrder++;
                                                            setBosses( new Set( list.map( e => JSON.stringify( e ) ) ) );
                                                        } }>Next participant</Button>

                                                    </Grid>

                                                    {/* <Stack spacing={ 2 } direction={ 'row' } justifySelf={ "center" } justifyContent={ 'center' } divider={ <Divider sx={ { bgcolor: "goldenrod" } } orientation="vertical" flexItem></Divider> }> */ }

                                                    {/* <Grid item xs={ 2 }>  <Divider sx={ { bgcolor: "goldenrod" } } orientation="vertical" flexItem></Divider> </Grid> */ }

                                                    <Grid item xs={ 4 }>


                                                        <Button variant="outlined" color="error" disabled={ !valueExists( currentFighter ) || currentFighter.killed || currentFighter.attempts === 0 } onClick={ () => {
                                                            currentFighter.attempts--;

                                                            setBosses( new Set( list.map( e => JSON.stringify( e ) ) ) );
                                                        } }>Remove attempt </Button>

                                                    </Grid>

                                                    <Grid item xs={ 4 }>  <Divider sx={ { borderColor: "goldenrod", "&::before": { borderColor: "goldenrod" }, "&::after": { borderColor: "goldenrod" } } } orientation="horizontal" ><Chip sx={ { color: "goldenrod", border: "thin solid goldenrod" } } label="attempts"></Chip> </Divider> </Grid>


                                                    <Grid item xs={ 4 }>

                                                        <Button variant="outlined" color="success" disabled={ !valueExists( currentFighter ) || currentFighter.killed } onClick={ () => {
                                                            currentFighter.attempts++;
                                                            setBosses( new Set( list.map( e => JSON.stringify( e ) ) ) );
                                                        } }>Add attempt </Button>
                                                    </Grid>

                                                    <Grid item xs={ 4 }>


                                                        <Button variant="outlined" color={ "error" } disabled={ !valueExists( currentFighter ) || !currentFighter.killed } onClick={ () => {
                                                            currentFighter.killed = false;
                                                            // list[ currentIndex ].currentOrder--;
                                                            setBosses( new Set( list.map( e => JSON.stringify( e ) ) ) );
                                                        } }>{ `Remove boss kill ` }</Button>

                                                    </Grid>
                                                    <Grid item xs={ 4 }>  <Divider sx={ { borderColor: "goldenrod", "&::before": { borderColor: "goldenrod" }, "&::after": { borderColor: "goldenrod" } } } orientation="horizontal" ><Chip sx={ { color: "goldenrod", border: "thin solid goldenrod" } } label="boss kill"></Chip> </Divider> </Grid>

                                                    <Grid item xs={ 4 }>

                                                        <Button variant="outlined" color={ "success" } disabled={ !valueExists( currentFighter ) || currentFighter.killed } onClick={ () => {
                                                            currentFighter.killed = true;
                                                            // list[ currentIndex ].currentOrder--;
                                                            setBosses( new Set( list.map( e => JSON.stringify( e ) ) ) );
                                                        } }>{ `Confirm boss kill` }</Button>
                                                    </Grid>

                                                </Grid>

                                                {


                                                    // const boss = JSON.parse( el );
                                                    // console.log( "create", createEvent );
                                                    // console.log( el );
                                                    // return <p></p>;
                                                }
                                                {/* </Paper> */ }

                                            </>
                                        }
                                    </div>
                                }
                                <Button sx={ { margin: 2 } } variant="contained" type="submit" disabled={ mode === modes.creation ? createEvent.bossList.length < 1 : !valueExists( selected ) || ( JSON.stringify( new Event( createEvent ) ) === JSON.stringify( dataRef.current?.[ selected ] ) ) }>{ mode === modes.creation ? "Create event" : "modify" }</Button>
                                { mode === modes.creation && <CustomCard event={ new Event( createEvent ) } loading={ !loaded } eventIndex={ 0 } user={ user } profile={ profile } /> }
                            </form>

                            {/* <BossCard/> */ }
                            {/* </CustomCard> */ }
                        </>
                    </div>

                    { mode === modes.modification && <Paper sx={ { ...paperSx, width: "90vw", display: "flex", margin: "2vh 2vw", padding: "2vh 2vw", flexWrap: "wrap", justifyContent: "center" } }>
                        { data.map( ( event, eventIndex ) => {
                            const isSelected = ( eventIndex === selected );
                            return (
                                <Box key={ event?.uid || eventIndex } sx={ { margin: "1vh 1vw", padding: "1vh 1vw", borderRadius: "20px", display: "flex", flexDirection: "column", border: isSelected ? "1px solid darkgoldenrod" : "" } } >
                                    <div>
                                        <Button sx={ { marginRight: 1, marginBottom: 1, borderColor: "goldenrod", color: "goldenrod", width: "fit-content" } } onClick={ () => {
                                            setSelected( eventIndex );
                                            setFinished( event.finished );
                                            console.log( event.currentIndex );
                                            setCurrentIndex( event.currentIndex );
                                            setOrganizerName( event.organizerName );
                                            setOrganizerUid( event.organizerUid );
                                            setName( event.eventName );
                                            setAbout( event.about );
                                            setUid( event.uid );
                                            setDateWithInitialValue( event.startTime.toDate() );
                                            const list = event.bossList.map( e => JSON.stringify( e ) );
                                            setBosses( new Set( list ) );


                                        } } variant={ "outlined" }>{ isSelected ? 'reset changes' : 'alter' }</Button>
                                        { isSelected && <Button variant={ "outlined" } sx={ { marginLeft: 1, borderColor: "black", marginBottom: 1, color: "black", width: "fit-content" } } onClick={ () => {
                                            resetState();
                                            setSelected( null );

                                        } } > Cancel selection </Button> }
                                    </div>
                                    <Box sx={ { display: "contents" } } >

                                        <CustomCard key={ eventIndex + "CustomCard" } event={ isSelected ? new Event( createEvent ) : event } user={ user } profile={ profile } loading={ initialLoading } eventIndex={ eventIndex } ></CustomCard>
                                    </Box>
                                </Box>
                            );

                        } ) }
                    </Paper> }
                </div>
            </div >
        );
    }
    return (
        <div className="App">
            {/* <div>Unauthorized</div> */ }
            <div className="App-header">
                { loaded ? <img alt='madge' style={ { width: 100, height: 100 } } src={ madge } /> : <Skeleton variant='circular'><img style={ { width: 100, height: 100 } } alt='madge' src={ madge } /></Skeleton> }
                { loaded ? <p style={ { color: "darkgoldenrod" } }>You aren't allowed here. Go back while you still can !</p> : <Skeleton variant="text"> <p style={ { color: "darkgoldenrod" } }>You aren't allowed here. Go back while you still can !</p> </Skeleton> }
                { loaded ? <Button variant={ "outlined" } sx={ { color: "darkgoldenrod", borderColor: "darkgoldenrod" } } onClick={ () => navigate( "/" ) }>stop snooping around, you maidenless heathen</Button> : <Skeleton variant="rounded"> <Button variant={ "outlined" } sx={ { color: "darkgoldenrod", borderColor: "darkgoldenrod" } } onClick={ () => navigate( "/" ) }>stop snooping around, you maidenless heathen</Button></Skeleton> }
            </div>
        </div>
    );
};

export default AdminPanel;