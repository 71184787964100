import { onAuthStateChanged } from 'firebase/auth';
import type { User } from 'firebase/auth';
import React from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { ResponsiveAppBar } from "../components/Appbar";
import { auth } from '../firebase';
import { UserProfile } from "../firebase";
const Root = () => {


    return (
        <AuthProvider>
            <ResponsiveAppBar />
            <Outlet />

        </AuthProvider>
    );
};

export default Root;

interface ContextTyping {
    loaded: boolean,
    user: User,
    profile: UserProfile,
    propagateProfile: ( profile: UserProfile | ( ( profile: UserProfile ) => UserProfile ) ) => void;
}

export const AuthContext = React.createContext<ContextTyping>( { loaded: false, user: null, profile: null, propagateProfile: ( _profile: UserProfile | ( ( profile: UserProfile ) => UserProfile ) ) => { } } );

function AuthProvider ( { children }: { children: React.ReactNode; } ) {
    const navigate = useNavigate();
    let [ user, setUser ] = React.useState<User>( null );
    let [ profile, setProfile ] = React.useState<UserProfile>( null );
    let [ loaded, setLoaded ] = React.useState<boolean>( false );
    React.useEffect( () => {

        const listener = onAuthStateChanged( auth, async ( firebaseUser ) => {
            console.info( "Auth changed... Please wait !" );
            setUser( firebaseUser );
            if ( firebaseUser && !profile )
            {
                console.info( "fetching profile" );
                const profile = await UserProfile.getUserFromServer( firebaseUser.uid );
                propagateProfile( profile );
            }
            else if ( loaded && !firebaseUser )
            {
                console.log( "redirecting to login" );
                setProfile( null );
                navigate( '/login', { state: { logout: true } } );
            }
            setLoaded( true );

        } );
        return () => {
            listener();
        };
        // todo Cleaner implementation ?
        //eslint-disable-next-line
    }, [] );


    let propagateProfile = ( profile: UserProfile | ( ( profile: UserProfile ) => UserProfile ) ) => {
        setProfile( profile );
    };

    let value = { user, profile, propagateProfile, loaded };

    return <AuthContext.Provider value={ value }>{ children }</AuthContext.Provider>;
}