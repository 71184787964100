import React, { useContext } from "react";
import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import { textStyle } from "../constants";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, UserProfile } from "../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../router/Root";
export default function CreateAccount () {
    const authContext = useContext( AuthContext );
    const [ email, setEmail ] = React.useState( "" );
    const [ password, setPassword ] = React.useState( "" );
    const [ verifyPassword, setVerifyPassword ] = React.useState( "" );

    const [ name, setName ] = React.useState( "" );
    const [ discord, setDiscord ] = React.useState( "" );
    const [ boss, setBoss ] = React.useState( "" );
    const [ character, setCharacter ] = React.useState( "" );
    const [ characters, setCharacters ] = React.useState( [] );

    const [ passwordIsTooShort, setPasswordIsTooShort ] = React.useState( false );
    const [ noDisplayName, setNoDisplayName ] = React.useState( false );
    const [ noEmail, setNoEmail ] = React.useState( false );
    const navigate = useNavigate();
    const removeFromCharacters = ( name: string ) => {
        const index = characters.findIndex( characterName => characterName === name );

        setCharacters( prev => {
            const newArray = prev.map( el => el );

            newArray.splice( index, 1 );
            return newArray;
        } );
    };

    const AllCharacters = () => {
        return ( <>{
            characters.map( ( characterName, index ) => {
                return ( <Paper key={ index } elevation={ 17 } sx={ { display: "inline-block", marginLeft: 1, marginRight: 1, padding: 2, borderRadius: 3, color: "#6DADEE", backgroundColor: "transparent" } }  >
                    <Typography fontSize={ " calc(10px + 1.5vh)" }>{ characterName }</Typography>
                    <Button variant={ "outlined" } color={ "error" } onClick={ () => removeFromCharacters( characterName ) } >Remove</Button></Paper> );
            } ) }
        </> );
    };
    const addCharacter = () => {
        if ( !character )
        {
            return;
        }
        setCharacters( prev => {
            const newArray = prev.map( el => el );
            newArray.push( character );
            setCharacter( "" );
            return newArray;
        } );
    };

    const submit = async () => {
        if ( !email )
        {
            setNoEmail( true );
        }
        if ( password.length < 8 )
        {
            setPasswordIsTooShort( true );
        }
        if ( !name )
        {
            setNoDisplayName( true );
        }
        if ( !email || !name || ( password.length < 8 ) )
        {
            return;
        }
        try
        {

            const credentials = await createUserWithEmailAndPassword( auth, email, password );
            updateProfile( credentials.user, { displayName: name } );
            const profile = new UserProfile( { uid: credentials.user.uid, displayName: name, discord, favourite_boss: boss, characters } );
            await profile.createFirestoreProfile();
            authContext.propagateProfile( profile );
            navigate( "/" );
            // credentials.user.

        }
        catch ( error )
        {
            //todo Have the error handled by user
            // const errorCode = error.code;
            // const errorMessage = error.message;
            console.error( error );
        }
    };
    return (

        <div className="App">
            <header className="App-header">
                <Paper sx={ { backgroundColor: "#1976d2", padding: 2, marginBottom: "15vh", marginTop: "5vh", borderRadius: 4 } } elevation={ 3 }>
                    Create an account !
                </Paper>

                <TextField value={ email } error={ noEmail } type={ "email" } sx={ textStyle } onChange={ ( e ) => setEmail( e.target.value ) } required id="filled-email" label={ noEmail ? "An email is required" : "E-mail" } variant="filled" />
                <TextField value={ password } error={ passwordIsTooShort } type={ "password" } sx={ textStyle } onChange={ ( e ) => setPassword( e.target.value ) } required id="filled-pw" label={ passwordIsTooShort ? "password must be at least 8 characters" : "Password (8 characters min.)" } variant="filled" />
                <TextField value={ verifyPassword } error={ passwordIsTooShort || password !== verifyPassword } type={ "password" } sx={ textStyle } onChange={ ( e ) => setVerifyPassword( e.target.value ) } required id="filled-pw-verification" label={ passwordIsTooShort ? "password must be at least 8 characters" : verifyPassword !== password ? "Passwords don't match" : "Password" } variant="filled" />
                <TextField value={ name } error={ noDisplayName } type={ "text" } sx={ textStyle } onChange={ ( e ) => setName( e.target.value ) } required id="filled-name" label={ noDisplayName ? "You need a displayed name" : "Your displayed name" } variant="filled" />
                <TextField value={ boss } type={ "text" } sx={ textStyle } onChange={ ( e ) => setBoss( e.target.value ) } id="filled-boss" label={ "Your favourite boss" } variant="filled" />
                <TextField value={ discord } type={ "text" } sx={ textStyle } onChange={ ( e ) => setDiscord( e.target.value ) } id="filled-boss" label={ "Your discord to contact you" } variant="filled" />

                <TextField value={ character } type={ "text" } sx={ textStyle } onChange={ ( e ) => setCharacter( e.target.value ) } id="filled-boss" label={ "Your character(s) name(s)" } variant="filled" />
                <Button variant="outlined" onClick={ addCharacter } >
                    Add character</Button>
                <Box sx={ { margin: 2 } } >

                    <AllCharacters />
                </Box>

                <Button sx={ { marginTop: 5, marginBottom: 3 } } onClick={ submit } variant="contained">Create</Button>
            </header >
        </div >
    );
}