import React from "react";
import Router from "./router/Router";
function App () {

  return (
    <Router />
  );


}


export default App;
