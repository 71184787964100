import * as React from 'react';
// import { ButtonFactory } from './ButtonFactory';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
// import { HomeNavigatorContext } from '@utils';
import { Link, useNavigate } from "react-router-dom";
import { auth } from '../firebase';
import { AuthContext } from '../router/Root';
import { whiteTextAndBorder } from '../constants';
// TODO : See Profile (anyone can) / Account : Change settings / Dashboard : See the upcoming events (?)

export const ResponsiveAppBar = ( props: any ) => {
    const [ anchorElNav, setAnchorElNav ] = React.useState<null | HTMLElement>( null );
    const [ anchorElUser, setAnchorElUser ] = React.useState<null | HTMLElement>( null );
    const { user } = React.useContext( AuthContext );
    const settings = user ?
        [
            { text: 'Profile', to: "profile" },
            // { text: 'Account', to: "userprofile" },
            { text: 'Dashboard', to: "events/participating" },
            { text: 'Logout', to: "/" }
        ]
        :
        [
            { text: 'Log in', to: 'login' },
            { text: 'Create Account', to: 'create' }
        ];

    const handleOpenNavMenu = ( event: React.MouseEvent<HTMLElement> ) => {
        setAnchorElNav( event.currentTarget );
    };
    const handleOpenUserMenu = ( event: React.MouseEvent<HTMLElement> ) => {
        setAnchorElUser( event.currentTarget );
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav( null );
    };


    const navigate = useNavigate();
    const handleCloseUserMenu = ( settingName: string ) => {
        if ( settingName === "Logout" )
        {
            auth.signOut();
        }
        setAnchorElUser( null );
    };
    return (
        <AppBar position="static" sx={ { height: "64px" } }>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <AdbIcon sx={ { display: { xs: 'none', md: 'flex' }, mr: 1 } } />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={ {
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            // fontFamily: 'monospace',
                            // fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        } }
                    >
                        DEKHAREN'S ELDEN RING EVENTS
                    </Typography>

                    <Box sx={ { flexGrow: 1, display: { xs: 'flex', md: 'none' } } }>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={ handleOpenNavMenu }
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={ anchorElNav }
                            anchorOrigin={ {
                                vertical: 'bottom',
                                horizontal: 'left',
                            } }
                            keepMounted
                            transformOrigin={ {
                                vertical: 'top',
                                horizontal: 'left',
                            } }
                            open={ Boolean( anchorElNav ) }
                            onClose={ handleCloseNavMenu }
                            sx={ {
                                display: { xs: 'flex', md: 'none' },
                                '& .MuiMenu-root': {
                                    display: 'flex',
                                },
                                '& .MuiPaper-root': {
                                    backgroundColor: "#1976d2",
                                    border: '2px solid black',
                                    borderRadius: 2,
                                    padding: 1,
                                },
                                '& .MuiMenu-list': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    alignContent: 'center'

                                }

                            } }
                        >
                            { user &&

                                <Button sx={ whiteTextAndBorder } onClick={ () => navigate( "events/participating" ) } variant={ "outlined" }>My Events</Button>
                            }
                            <Button sx={ whiteTextAndBorder } onClick={ () => navigate( "events/upcoming" ) } variant={ "outlined" }>See upcoming events</Button>
                            <Button sx={ whiteTextAndBorder } onClick={ () => navigate( "events/current" ) } variant={ "outlined" }>See current events</Button>

                        </Menu>
                    </Box>
                    <AdbIcon sx={ { display: { xs: 'flex', md: 'none' }, mr: 1 } } />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={ {
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            // fontFamily: 'monospace',
                            // fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        } }
                    >
                        DEKHAREN
                    </Typography>
                    <Box sx={ { flexGrow: 1, display: { xs: 'none', md: 'flex' } } }>
                        { user && (
                            <Button variant={ "outlined" } sx={ whiteTextAndBorder } onClick={ () => navigate( "events/participating" ) }>My Events</Button>
                        )
                        }
                        <Button variant={ "outlined" } onClick={ () => navigate( "events/upcoming" ) } sx={ whiteTextAndBorder }>See upcoming events</Button>
                        <Button variant={ "outlined" } onClick={ () => navigate( "events/current" ) } sx={ whiteTextAndBorder }>See current events</Button>
                    </Box>

                    <Box sx={ { flexGrow: 0 } }>
                        <Tooltip title="Open settings">
                            <IconButton


                                onClick={ handleOpenUserMenu } sx={ { p: 0 } }>
                                {//todo : set SRC for avatar 
                                }
                                <Avatar alt={ user?.displayName ? user.displayName : "?" } sx={ { backgroundColor: '#7a6800' } } >{ user?.displayName ? user.displayName[ 0 ] : "?" }</Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={ { mt: '45px' } }
                            id="menu-appbar"
                            anchorEl={ anchorElUser }
                            anchorOrigin={ {
                                vertical: 'top',
                                horizontal: 'right',
                            } }
                            keepMounted
                            transformOrigin={ {
                                vertical: 'top',
                                horizontal: 'right',
                            } }
                            open={ Boolean( anchorElUser ) }
                            onClose={ handleCloseUserMenu }
                        >
                            { settings.map( ( setting ) => (
                                <MenuItem key={ setting.text } component={ Link } to={ setting.to } onClick={ () => handleCloseUserMenu( setting.text ) }>
                                    <Typography textAlign="center" style={ { textDecorationLine: "none" } }>{ setting.text }</Typography>
                                </MenuItem>
                            ) ) }
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};