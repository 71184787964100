import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import Obj from "./bosslist.json";
import { Paper } from '@mui/material';
import "./bosslist.css";
import { adminTextFieldSx } from '../constants';

const _REGION_LIST = Obj.value;

const GroupHeader = styled( 'div' )( ( { theme } ) => ( {
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: 'darkgoldenrod',
    backgroundColor: "black"
    //     theme.palette.mode === 'light'
    //         ? lighten( theme.palette.primary.light, 0.85 )
    //         : darken( theme.palette.primary.main, 0.8 ),
} ) );

const GroupItems = styled( 'ul' )( {
    color: 'darkgoldenrod',
    padding: 0,
    backgroundColor: "#041425"
} );

export default function RenderGroup ( props: { onChange: ( event: React.SyntheticEvent<Element, Event>, value: any ) => void; } ) {
    const _options = _REGION_LIST.map( el => {
        return el.bosses.map( e => ( { ...e, region: el.region } ) );
    } );
    interface Option {
        region: string;
        name: string;
        link: string;
    }
    const options: Array<Option> = [];
    for ( let i = 0; i < _options.length; i++ )
    {
        options.push( ..._options[ i ] );
    }
    // .map( ( option ) => {
    //     const firstLetter = option.title[ 0 ].toUpperCase();
    //     return {
    //         firstLetter: /[0-9]/.test( firstLetter ) ? '0-9' : firstLetter,
    //         ...option,
    //     };
    // } );
    return (
        <Autocomplete
            id="boss-autocomplete"
            options={ options }
            isOptionEqualToValue={ ( option, value ) => {
                return JSON.stringify( option ) === JSON.stringify( value );
            } }
            groupBy={ ( option: Option ) => option.region }
            getOptionLabel={ ( option: string | Option ) => {
                if ( typeof option !== "string" )
                {
                    return option?.name;
                }
                return option;
            } }
            PaperComponent={ ( { children } ) => (
                <Paper style={ { background: "black", padding: 6, } }>{ children }</Paper>
            ) }
            ListboxProps={ {
                className: "myCustomList"
            } }
            // PopperComponent={<Popper></Popper>}
            sx={ {
                width: "50vw", backgroundColor: "#041425", color: "goldenrod",
                scrollbarColor: "red",
                popper: {
                    backgroundColor: "#041425",
                },
            } }
            renderInput={ ( params ) => <TextField { ...params } sx={ adminTextFieldSx } label="Choose a boss" /> }
            renderOption={ ( props, option, index ) => {
                let key: React.Key;
                if ( typeof option === 'string' )
                {

                    key = `listItem-${ index.index }-${ option }`;
                }
                else key = `listItem-${ index.index }-${ option.region }-${ option.name }`;
                // console.log( key );
                return (
                    <li { ...props } key={ key }>

                        { typeof option !== "string" ? option.name : option }
                    </li>
                );
            } }
            renderGroup={ ( params ) => (
                <li key={ params.key } >
                    <GroupHeader>
                        { params.group }
                    </GroupHeader>
                    < GroupItems > { params.children } </GroupItems>
                </li>
            ) }
            // onChange={ ( event, value ) => { console.log( value ); } }
            { ...props }
        />
    );
};