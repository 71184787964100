import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from 'react';
import type { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../router/Root';
import { Button, Snackbar, TextField } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircleLoader from "react-spinners/CircleLoader";
import Draggable from 'react-draggable';
import type { DraggableEventHandler } from 'react-draggable';
import { arrayMoveImmutable } from 'array-move';
import { updateEmail, updateProfile } from 'firebase/auth';
import { Alert } from '../components/Alert';
import { FieldPaper } from '../components/FieldPaper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider/Divider';
type RequireOnlyOne<T, Keys extends keyof T = keyof T> =
    Pick<T, Exclude<keyof T, Keys>>
    & {
        [ K in Keys ]-?:
        Required<Pick<T, K>>
        & Partial<Record<Exclude<Keys, K>, undefined>>
    }[ Keys ];

interface DisplayOrEditType { setter?: ( val: string ) => void, controlled?: boolean, multiline?: boolean, value: string, baseValue: string, label: string, Validation?: ( value: any | string ) => React.ReactElement; }

type DisplayOrEditProps = RequireOnlyOne<DisplayOrEditType, "controlled" | "value">;

const calculateIndex = ( y: number, height: number ) => {
    return Math.floor( y / height );

};


const pos = ( x: number, y: number ) => ( { x, y } );
const resetPos = pos( 0, 0 );
const divAdjust = { display: "flex", width: "100%", justifyContent: "center" };

//!COMPONENT

const Profile = () => {
    const authContext = useContext( AuthContext );
    const navigate = useNavigate();
    const { user, profile, loaded, propagateProfile } = authContext;
    //care : displayName should be updated on both
    const [ open, setOpen ] = React.useState( false );
    const [ openError, setOpenError ] = React.useState( false );
    const theme = useTheme();
    const isLargeEnough = useMediaQuery( theme.breakpoints.up( 'sm' ) );
    const containerClass = isLargeEnough ? 'profile-container flex-row' : 'profile-container flex-column';
    const subContainerClass = isLargeEnough ? 'subcontainer profile-subcontainer-isRow' : 'subcontainer profile-subcontainer-isColumn';
    const paperSx = { backgroundColor: "#282c34", filter: "brightness( 130%)", borderRadius: 6 };
    const [ displayName, setDisplayName ] = useState( profile?.displayName );
    const [ favourite_boss, setFavourite_boss ] = useState( profile?.favourite_boss );
    const [ about, setAbout ] = useState( profile?.about );
    const [ discord, setDiscord ] = useState( profile?.discord );
    const [ newCharacter, setNewCharacter ] = useState( "" );
    const [ email, setEmail ] = useState( "" );

    const [ characters, setCharacters ] = useState( profile?.characters || [ null, null ] );

    const comparedState = {
        isAdmin: profile?.isAdmin,
        displayName,
        discord,
        favourite_boss,
        characters,
        about,
    };
    const profileView = { ...profile?.profile };
    if ( profile )
    {

        delete profileView.uid;
        delete profileView.eventsAttended;
    }
    const disableValidation = JSON.stringify( profileView ) === JSON.stringify( comparedState ) && user?.email === email;

    useEffect( () => {
        if ( profile?.characters )
        {
            setCharacters( profile.characters );
        }

        if ( !user && loaded )
        {
            navigate( "/login" );
        }

    }, [ profile?.characters, user, loaded, navigate ] );

    const moveCharacter = ( from: number, to: number ) => arrayMoveImmutable( characters, from, to );
    const renameCharacter = ( val: string, index: number ) => {
        const arr = [ ...characters ];
        arr[ index ] = val;
        setCharacters( arr );
    };
    const reorder = ( i: number, diff: number ) => {
        const newIndex = ( i + diff );
        const destinationIndex = newIndex >= characters.length ? characters.length - 1 : newIndex < 0 ? 0 : newIndex;
        const arr: string[] = moveCharacter( i, destinationIndex );
        setCharacters( arr );
    };
    const onClick = async () => {
        try
        {

            if ( profile?.displayName !== displayName )
            {
                updateProfile( user, {
                    displayName
                } );
            }
            if ( user.email !== email )
            {
                updateEmail( user, email ).catch( reason => console.log( reason, "might need to reauthenticate" ) );
            }
            const newProfile = await profile.updateFirestoreProfile( comparedState );
            propagateProfile( newProfile );
            setOpen( true );
        }
        catch ( error )
        {
            setOpenError( true );
        }
    };
    const handleClose = ( cb: React.Dispatch<React.SetStateAction<boolean>> ) => ( _event: React.SyntheticEvent | Event, reason?: string ) => {
        if ( reason === 'clickaway' )
        {
            return;
        }

        cb( false );
    };
    const handleCloseSuccess = handleClose( setOpen );
    const handleCloseError = handleClose( setOpenError );

    return (
        <div className="App">
            <div className="App-background">

                <Typography style={ { margin: "2vh", color: '#7a6800', filter: 'brightness(120%)', fontSize: "inherit" } }>Edit your profile</Typography>
                <div className={ containerClass }>
                    <Paper className={ subContainerClass } elevation={ 20 } sx={ paperSx } id="profile-left">
                        <DisplayOrEdit value={ email } setter={ setEmail } baseValue={ user?.email } label={ "Your email : " } />
                        <DisplayOrEdit value={ displayName } setter={ setDisplayName } baseValue={ profile?.displayName } label={ "Your displayed name: " } />
                        <DisplayOrEdit value={ discord } setter={ setDiscord } baseValue={ profile?.discord } label={ "Your discord: " } />
                        <DisplayOrEdit value={ favourite_boss } setter={ setFavourite_boss } baseValue={ profile?.favourite_boss } label={ "Your favorite boss: " } />
                        <DisplayOrEdit value={ about } setter={ setAbout } baseValue={ profile?.about } multiline label={ "About you: " } />

                    </Paper >
                    <Divider flexItem orientation='vertical' variant='middle' sx={ { borderRightWidth: 2 } } ></Divider>
                    <Paper className={ subContainerClass } elevation={ 20 } sx={ paperSx } id="profile-right">
                        { characters.map( ( character, index ) => {
                            return <DraggableComponent reorder={ reorder } i={ index } key={ index }><DisplayOrEdit setter={ ( val ) => renameCharacter( val, index ) } value={ characters[ index ] } baseValue={ character } label={ index ? `Character ${ index + 1 } name :` : "Main character's name:" } /></DraggableComponent>;

                        } ) }

                        <div style={ divAdjust }>
                            {/* hacky way to sync the displays. I should control them from the parent component, but eff it */ }
                            <DisplayOrEdit value={ newCharacter } setter={ setNewCharacter } Validation={ ( { val } ) => <Button sx={ { marginLeft: 2 } } variant={ "contained" } onClick={ () => val && setCharacters( [ ...characters, val ] ) } >Add</Button> } baseValue={ !profile ? null : "" } label={ "Add new character:" } />
                        </div>

                    </Paper>
                </div>
                <Button size='large' onClick={ onClick } sx={ { marginBottom: 2, "&.Mui-disabled": { color: "#A3A3A3", } } } disabled={ loaded ? disableValidation : true } variant={ "contained" }>Update</Button>
                <Snackbar
                    open={ open }
                    autoHideDuration={ 6000 }
                    onClose={ handleCloseSuccess }

                >
                    <Alert onClose={ handleCloseSuccess } severity="success" sx={ { width: '100%' } }>
                        Your profile was successfully saved !
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={ openError }
                    autoHideDuration={ 6000 }
                    onClose={ handleCloseError }

                >
                    <Alert onClose={ handleCloseError } severity="error" sx={ { width: '100%' } }>
                        An error occured. Try again later or contact the support.
                    </Alert>
                </Snackbar>
            </div>
        </div>

    );

};

export default Profile;


//!COMPONENT
const DraggableComponent = ( { children, reorder, i }: { children: any, reorder?: any, i: number; } ) => {

    const nodeRef = React.useRef( null );
    const [ zIndex, set_z_Index ] = useState( 1 );
    const [ position, setPosition ] = useState( resetPos );
    const onDragStart: DraggableEventHandler = () => set_z_Index( 40 );
    const onDragStop: DraggableEventHandler = ( _, data ) => {
        set_z_Index( 1 );
        const indexDiff = calculateIndex( data.y, data.node.clientHeight );
        if ( !indexDiff )
        {
            setPosition( resetPos );
        }
        else
        {
            // setPosition( null );
            reorder && reorder( i, indexDiff );
        }
    };

    return (
        <Draggable cancel={ ".someclass" } position={ position } nodeRef={ nodeRef } axis={ "y" } bounds={ `.subcontainer` } onStart={ onDragStart } onStop={ onDragStop } >
            <div style={ { zIndex, ...divAdjust } } ref={ nodeRef }>{ children }</div>
        </Draggable>
    );
};
//!COMPONENT

const DisplayOrEdit = ( { baseValue, label, Validation, multiline = false, value, setter }: DisplayOrEditProps ) => {

    // const [ value, setValue ] = useState( baseValue );
    const [ loading, setLoading ] = useState( true );


    useEffect( () => {
        setter && setter( baseValue );
        if ( baseValue !== undefined && baseValue !== null )
        {
            setTimeout( () => {
                setLoading( false );

            }, 350 );
        }
        //todo find a way to not disable ? "setter" is ever changing
        //eslint-disable-next-line
    }, [ baseValue, ] );

    const onChange = ( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
        // setValue( e.target.value )
        setter( e.target.value );
    };
    //!Styles
    const override: CSSProperties = { minHeight: "5vh", minWidth: "5vh", display: "flex", justifyContent: "center", alignContent: "center", flexWrap: "wrap", padding: "25px 12px 8px" };
    const inputProps = { textAlign: 'center', textJustify: "auto", fontSize: "calc(10px + 1.5vmin)", color: "#7a6800", minHeight: "5vh" };
    const multilineInputProps = { textAlign: 'center', textJustify: "auto", fontSize: "calc(10px + 1.5vmin)", color: "#7a6800", minHeight: "10vh" };
    const [ hasClass, setClass ] = useState( false );
    const labelProps = { color: "cyan", fontSize: "calc(7px + 0.7vmin)" };
    const sxProps = {
        '& :-webkit-autofill': {
            WebkitTextFillColor: 'white',
        },
        '& :-webkit-autofill:focus': {
            WebkitTextFillColor: 'white'
        },
        '& :-webkit-autofill:hover': {

            WebkitTextFillColor: 'white'
        },
        borderRadius: 3, width: "90%", alignSelf: "center", textAlign: "center", backgroundColor: "#041425", filter: "brightness( 140%)", autofill: inputProps, input: inputProps, label: labelProps, textarea: multilineInputProps
    };
    //!Render
    // MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-filled MuiFormLabel-colorPrimary MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-filled css-e4w4as-MuiFormLabel-root-MuiInputLabel-root
    return (
        <FieldPaper >
            <ArrowForwardIosIcon sx={ { alignSelf: "center", marginRight: 2 } }></ArrowForwardIosIcon>
            { loading &&
                <div style={ override }>

                    <CircleLoader color='cyan' />
                </div>
            }
            { !loading && <><TextField
                onMouseDown={ ( e ) => { e.stopPropagation(); } }
                onMouseEnter={ () => {
                    console.log( "setting Class" );
                    setClass( true );
                } } inputProps={ { className: "someclass" } } multiline={ multiline } sx={ sxProps } label={ label } variant='filled' value={ value } onChange={ onChange } />
                { Validation && <Validation val={ value } /> } </> }
        </FieldPaper>

    );

};

